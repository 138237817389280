// VN
export const locale = {
	lang: 'vn',
	data: {
		TRANSLATOR: {
			SELECT: 'Chọn ngôn ngữ',
		},
		THEME: {
			LIGHT: 'Sáng',
			DARK: 'Tối',
		},
		MENU: {
			NEW: 'Mới',
			ACTIONS: 'Hành động',
			CREATE_POST: 'Tạo mới',
			PAGES: 'Trang',
			FEATURES: 'Tính năng',
			APPS: 'Apps',
			DASHBOARD: 'Tổng quan',
			MAP: 'Bản đồ',
			REPORT: 'Báo cáo',
			REPORT_FUEL: 'Nạp xả',
			MANAGE: 'Quản lý',
			MANAGER_USER: 'Tài khoản',
			MANAGER_DEVICE: 'Thiết bị',
			MANAGER_DRIVER: 'Lái xe',
			MANAGER_POI: 'Điểm đánh dấu',
      		CHARGE_STATION: 'Trạm sạc',
			GROUP_DEVICE: 'Nhóm thiết bị',
			COMMAND: 'Gửi lệnh',
			ADMIN: 'Quản trị viên',
			SETTING_SYSTEM: 'Cấu hình hệ thống',
			MANAGE_PERMISSIONS: 'Quản lý quyền',
			MANAGE_ROLES: 'Quản lý vai trò',
			LOGIN_PAGE: 'Trang đăng nhập',
			SETTING_DEVICE: 'Cấu hình thiết bị',
			SIM_TYPE: 'Loại sim',
			DEVICE_TYPE: 'Loại thiết bị',
			TRANSPORT_TYPE: 'Loại hình vận tải',
			TRANSPORT_TYPE_QCVN: 'Loại hình vận tải QCVN',
			TRIP: 'Chuyến đi',
			SENSOR_MODEL: 'Mẫu cảm biến',
			ICON: 'Biểu tượng thiết bị',
			USER_ALIAS: 'Bí danh người dùng',
			ROLE_ALIAS: 'Bí danh vai trò',
			ALERT: 'Quy tắc cảnh báo',
			REPORT_DEVICE: 'Trạng thái thiết bị',
			REPORT_ROUTE: 'Lộ trình',
			REPORT_ROUTE_SIMPLE: 'Lộ trình rút gọn',
			REPORT_ENGINE: 'Thời gian máy nổ',
			REPORT_GENERAL: 'Tổng hợp (thiết bị)',
			REPORT_SUMMARY_DAY: 'Tổng hợp theo ngày',
			REPORT_STOP_POINT: 'Điểm dừng',
			REPORT_GEOFENCE_SUMMARY: 'Tổng hợp ra vào vùng',
			REPORT_GEOFENCE_DETAIL: 'Chi tiết ra vào vùng',
			REPORT_QCVN_STOP: 'QCVN31-BC dừng đỗ',
			REPORT_QCVN_SPEED: 'QCVN31-BC Tốc độ',
			REPORT_STATUS_HISTORY: 'Lịch sử trạng thái',
			REPORT_TOLL_FREE: 'Trạm thu phí',
			REPORT_TASK_REMIND: 'Nhắc nhở',
			MANAGE_GEOFENCE: 'Vùng địa lý',
			MANAGE_GEOFENCE_GROUP: 'Nhóm vùng địa lý',
			REPORT_TEMPERATURE: 'Nhiệt độ',
			SCHEDULE_REPORT: 'Lập lịch báo cáo',
			MANAGE_ALERTS: 'Thông báo',
			POINTS_MANAGE: 'Quản lý điểm',
			RENEWS_HISTORY: 'Lịch sử gia hạn',
			TRANSACTION_HISTORY: 'Lịch sử giao dịch',
			POINT_MANAGE: 'Quản lý điểm',
			UTILITIES: 'Tiện ích',
			CARD_SERVICE: 'Dịch vụ thẻ',
			INSERT_CARD: 'Nạp thẻ',
			ACTIVITY_LOG: 'Nhật ký hoạt động',
			// UTILITIES:"Cáo báo nhiên liệu",
			// CARD_SERVICE:"Dịch vụ thẻ",
			// INSERT_CARD:"Nạp thẻ",
			// ACTIVITY_LOG :"Nhật ký hoạt động",
			SYSTEM_LOG: 'Nhật ký hệ thống',
			BALANCE: 'Số dư',
			SYNTHESIS_REPORT: 'Báo cáo tổng hợp',
			NOTIFI_GLOBAL: 'Thông báo hệ thống',
			MESSAGE: 'Tin nhắn',
			TRACKINGS: 'Theo dõi nhiều xe',
			FEEDBACK: 'Phản hồi',
			DATA_LOG: 'Dữ liệu lịch sử',
			FUEL: 'Nhiên liệu',
			POI_TYPE: 'Loại điểm đánh dấu',
			TOLL_STATION: 'Trạm thu phí',
			MAP_API_KEY: 'Map API key',
			TOLL_ROAD: 'Đường thu phí',
			TOLL_SEGMENT: 'Tuyến thu phí',
			TOLL_FEE: 'Ước tính phí đường bộ',
			TRACKING_GEOFENCES: 'Theo dõi theo vùng',
			TRACKING_LANDMARKS: 'Theo dõi theo điểm',
			TECHNICAL_SUPPORT: 'Hỗ trợ kỹ thuật',
			DEBUG: 'Lịch sử dữ liệu thô',
			SIM_INFO: 'Lịch sử hoạt động SIM ',
			DEVICE_LOGIN: 'Lịch sử kết nối',
			LOST_SIGNAL: 'Mất tín hiệu',
			DATA_GROUP_MANAGEMENT: "Quản lý nhóm dữ liệu",
			CAMERA: 'Theo dõi camera',
			INFO: 'Thông tin',
			CONNECTOR: 'Cổng sạc',
			RFID_TAG: 'Thẻ RFID',
			WALLET: 'Ví',
			BOOKING_HISTORY: 'Lịch sử đặt lịch',
			CHANGE_BALANCE: 'Số dư thay đổi',
			REPAIR: 'Chi phí sửa chữa',
			ROLE: 'Vai trò',
			PERMISSION: 'Quyền',
			DEVICE_ICON: 'Biểu tượng thiết bị',
			CONNECTOR_TYPE: 'Loại cổng sạc',
			CONNECTOR_ICON: 'Biểu tượng cổng sạc',
			TECHNICAL_ASSISTANCE: 'Hỗ trợ kĩ thuật',
			RAW: 'Lịch sử dữ liệu thô',
			GATEWAY_CONNECTION: 'Các cổng kết nối',
			DEVICE_CONFIGURATION: 'Cấu hình thiết bị',
			SYSTEM_CONFIGURATION: 'Cấu hình hệ thống',
			BOOKING: 'Đặt lịch',
			SERVICE_PRICE: 'Giá dịch vụ',
			BANK: 'Ngân hàng',
			TERMINAL: 'Đơn vị kinh doanh',
			SYSTEM: 'Hệ thống',
			DEVICE_STATUS: 'Trạng thái thiết bị',
			VOUCHER: 'Ví ưu đãi'
		},
		AUTH: {
			GENERAL: {
				SIGNIN: 'Đăng nhập',
				OR: 'hoặc',
				SUBMIT_BUTTON: 'Gửi đi',
				NO_ACCOUNT: 'Chưa có tài khoản?',
				SIGNUP_BUTTON: 'Đăng ký',
				FORGOT_BUTTON: 'Quên mật khẩu?',
				BACK_BUTTON: 'Quay lại',
				PRIVACY: 'Về chúng tôi',
				LEGAL: 'Pháp lý',
				CONTACT: 'Liên hệ',
				WELCOME: 'Chào mừng bạn đến với Navitrack',
				DESCRIPTION:
					'Công Ty Cổ Phần Công nghệ Điện Tử & Viễn Thông Việt Nam (Navitrack) là một trong những đơn vị đi đầu trong lĩnh vực phân phối, sản xuất thiết bị định vị tại Việt Nam.',
				DONT_HAVE_ACCOUNT: 'Bạn chưa có tài khoản?',
			},
			LOGIN: {
				TITLE: 'Tài khoản đăng nhập',
				BUTTON: 'Đăng nhập',
			},
			FORGOT: {
				TITLE: 'Quên mật khẩu?',
				DESC: 'Nhập email để thiết lập lại mật khẩu',
				SUCCESS: 'Đã thiest lập lại mật khẩu thành công',
			},
			RESET: {
				TITLE: 'Cài lại mật khẩu mới?',
			},
			REGISTER: {
				TITLE: 'Đăng ký',
				DESC: 'Nhập email',
				SUCCESS: 'Tài khoản đã được đăng ký thành công.',
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Tên đầy đủ',
				PASSWORD: 'Mật khẩu',
				CONFIRM_PASSWORD: 'Nhập lại mật khẩu',
				USERNAME: 'Tài khoản',
			},
			VALIDATION: {
				INVALID: '{{name}} không hợp lệ',
				REQUIRED: '{{name}} bắt buộc',
				MIN_LENGTH: '{{name}} tối thiểu là {{min}} ký tự',
				AGREEMENT_REQUIRED:
					'Chấp nhận các điều khoản và điều kiện là bắt buộc',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Tài khoản hoặc mật khẩu không đúng',
				REQUIRED_FIELD: 'Không để trống',
				MIN_LENGTH_FIELD: 'Độ dài tối thiểu:',
				MAX_LENGTH_FIELD: 'Độ dài tối đa:',
				INVALID_FIELD: 'Giá trị không hợp lệ',
			},
			MESSAGE: {
				TEST: 'Thử nghiệm',
			},
			DOWNLOAD: {
				TITLE: 'Tải ứng dụng'
			}
		},
		DASHBOARD: {
			NOT_FOUND: 'Không có dữ liệu',
			STATUS: 'Trạng thái',
			STATICIC: 'Thống kê',
			DEVICE: 'Thiết bị',
			NO_DEVICE: 'Tài khoản chưa có thiết bị',
			USER: 'Tài khoản',
			CATEGORY: 'Danh mục',
			INFO_DISTRIBOUTOR: 'Thông tin đại lý',
			TOTAL_POINT: 'Tổng điểm',
			OPTION: 'Cấu hình hiển thị',
			AVERAGE: 'Trung bình',
			PARAMS: {
				ONLINE: 'Trực tuyến',
				OFFLINE: 'Ngoại tuyến',
				STOCK: 'Chưa bán',
				EXPIRED: 'Hết hạn',
				INACTIVE: 'Chưa kích hoạt',
				ACTIVE: 'Kích hoạt',
				TOTAL: 'Tổng số',
				ALERTS: 'Cảnh báo',
				SELL: 'Đã bán',
				CHART_STATUS: 'Biểu đồ trạng thái',
			},
			STATIC: {
				TOP_10_STOP: 'Dừng nhiều',
				TOP_10_IDLING: 'Dừng xe nổ máy',
				TOP_10_DISTANCE: 'Di chuyển',
				DURATION: 'Thời gian',
				DISTANCE: 'Quãng đường',
				MOVE: 'Di chuyển',
				IDLING_LABEL: 'Thời gian (phút)',
				MIN: 'phút',
				HOURS: 'giờ',
				SECONDS: 'giây',
				DURATION_LABEL: 'Thời gian (giờ)',
				DISTANCE_LABEL: 'Quãng đường (km)',
			},
			SUMMARY_USER: {
				TIME: 'Thời gian sạc',
				AMOUNT: 'Tổng giao dịch',
				POWER: 'Điện năng tiêu thụ',
				TRANSACTION: 'Số lượng giao dịch',
				DEVICE_CONNECT: 'Thiết bị đang hoạt động',
				TOTAL_DEVICE: 'Tổng thiết bị'
			},
			SUMMARY_BOOKING: {
				TITLE: 'Trạng thái đặt lịch',
				STATUS: 'Trạng thái'
			},
			SUMMARY_CASH_FLOW: {
				TITLE: 'Doanh thu theo tháng',
				CASH_IN: 'Tiền vào',
				CASH_OUT: 'Tiền ra',
				SAVE_AS_IMAGE: 'Lưu ảnh về máy',
				LINE_CHART: 'Biểu đồ đường',
				BAR_CHART: 'Biểu đồ cột',
				REVENUE: 'Doanh thu'
			},
			SUMMARY_ELECTRIC: {
				TITLE: 'Điện năng tiêu thụ (kWh)',
				SAVE_AS_IMAGE: 'Lưu ảnh về máy',
				LINE_CHART: 'Biểu đồ đường',
				BAR_CHART: 'Biểu đồ cột',
				ELECTRIC_POWER: 'Điện năng tiêu thụ'
			},
			TOP5_EV_CHARGER: {
				TITLE: 'Danh sách trạm đã sạc',
				CHARGER_NAME: 'Tên trạm',
				NUMBER_OF_CHARGING_TIME: 'Số lần sạc',
				TOTAL_AMOUNT: 'Tổng chi tiêu'
			},
			TOP_TERMINAL_REVENUE: {
				TITLE: 'Top 10 đơn vị có doanh thu cao nhất',
				REVENUE: 'Doanh thu'
			},
			USER_REVENUE_PER_DAY: {
				TITLE: 'Doanh thu theo ngày'
			},
			MANAGER: {
				SUMMARY_STATION: {
					TITLE: 'Trạng thái thiết bị',
					NOT_ACTIVE: 'Không hoạt động',
					ACTIVE: 'Hoạt động',
					EXPIRED: 'Hết hạn',
					DISCONNECT: 'Mất kết nối'
				},
				SUMMARY_CONNECTOR: {
					TITLE: 'Trạng thái các đầu sạc',
					AVAILABLE: 'Sẵn sàng',
					MAINTENANCE: 'Bảo trì',
					INUSE: 'Đang sạc'
				},
				SUMMARY_DAILY_REVENUE: {
					CHARGING_TYPE: 'Hình thức sạc',
					REVENUE: 'Doanh thu',
					BOOKING: 'Đặt lịch',
					ANONYMOUS: 'Vãng lai',
					NUMBER_OF_CHARGING_TIME: 'Số lần sạc'
				},
				SUMMARY_REVENUE:{
					MONEY: 'Tiền (VNĐ)',
					TITLE: 'Doanh thu (VNĐ)',
					REVENUE: 'Doanh thu',
					PERSONAL: 'Doanh thu cá nhân',
					TOTAL_REVENUE: 'Tổng doanh thu'
				}
			},
			USER_TYPE: {
				CUSTOMER: 'Cá nhân',
				MANAGER: 'Quản lý',
				TITLE: 'Loại',
				PERSONAL_EXPENDITURE: 'Chi tiêu cá nhân',
				PERSONAL_REVENUE: 'Doanh thu cá nhân',
				TOTAL_REVENUE: 'Tổng doanh thu'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields',
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION:
						'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted',
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION:
						'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted',
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE:
						'Selected customers status have successfully been updated',
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created',
				},
			},
			MESSAGE: {},
		},
		ADMIN: {
			GENERAL: {
				SIM_TYPE: 'loại SIM',
				DEVICE_TYPE: 'loại thiết bị',
				TRANSPORT_TYPE: 'loại hình vận tải',
			},
			SIM_TYPE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Tạo mới loại SIM',
					TITLE_FORM_EDIT: 'Sửa loại SIM',
					NAME_KEY: 'Mã',
					NAME: 'Tên',
					CREATED_DATE: 'Ngày tạo',
					SORT_ORDER: 'Thứ tự',
					ACTIONS: 'Chức năng',
				},
				VALIDATION: {},
				MESSAGE: {},
			},
			PERMISSION: {
				GENERAL: {
					TITLE_FORM_ADD: 'Tạo mới quyền',
					TITLE_FORM_EDIT: 'Sửa quyền',
					DESCRIPTION: 'Mô tả',
					NAME: 'Tên',
					GROUP: 'Nhóm',
					PERMISSION_NAME: 'Tên quyền',
					PERMISSION: 'quyền',
					UPDATE_PERMISSION_FAILED: 'Cập nhật quyền thất bại!',
					DELETE_PERMISSION_FAILED: 'Xóa quyền thất bại!',
					DELETE_PERMISSION_SUCCESS: 'Xóa quyền thành công!',
					UPDATE_PERMISSION_NAME: ' Cập nhật quyền ',
					ADD_PERMISSION_NAME: 'Thêm quyền ',
					INHERIT: 'Kế thừa',
				},
			},
			ROLE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Tạo mới vai trò',
					TITLE_FORM_EDIT: 'Sửa vai trò',
					DESCRIPTION: 'Mô tả',
					NAME: 'Tên',
					NAME_PARENT: 'Tên cha',
					ROLE_NAME: 'Tên vai trò',
					ROLE: 'vai trò',
					CHOICE_PERMISSIONS: 'Chọn quyền',
					KEY: 'Khóa'
				},
				MESSAGE: {
					UPDATE_ROLE_SUCCESS: ' Cập nhật vai trò thành công',
					ADD_ROLE_SUCCESS: ' Tạo mới vai trò  thành công',
					DELETE_ROLE_SUCCESS: ' Xóa vai trò thành công',
					UPDATE_ROLE_FAILED: 'Cập nhật vai trò thất bại!',
					ADD_ROLE_FAILED: 'Thêm mới vai trò thất bại!',
					DELETE_ROLE_FAILED: 'Xóa vai trò hất bại!',
				},
			},
			CONNECTOR: {
				NAME: 'Tên cổng sạc',
				DEVICE: 'Trạm sạc',
				TYPE: 'Loại cổng sạc',
				OUTPUT_CURRENT_TYPE:'Loại dòng điện đầu ra',
				SUPPLY_INPUT: 'Cung cấp đầu vào',
				MAXIMUM_OUTPUT_CURRENT: 'Dòng điện đầu ra tối đa (A)',
				MAXIMUM_OUTPUT_POWER: 'Công suất đầu ra tối đa (kWh)',
				MAXIMUM_OUTPUT_VOLTAGE: 'Điện áp đầu ra tối đa',
				GENERAL:{
					TITLE_FORM_ADD: 'Tạo mới cổng sạc',
					TITLE_FORM_EDIT: 'Sửa cổng sạc',
					CONNECTOR: 'cổng sạc'
				},
				CONNECTOR_NUMBER: 'Vị trí cổng sạc',
				NUMBER_OF_CONNECTOR: 'Số lượng cổng sạc muốn tạo',
				CREATE_MULTI_CONNECTOR: 'Tạo nhiều cổng sạc',
				CONNECTOR_NAME_DEFAULT: 'Cổng'
			},
			CONNECTOR_TYPE: {
				GENERAL: {
					NAME: 'Tên',
					SHORT_NAME: 'Tên viết tắt',
					TITLE_FORM_ADD: 'Tạo mới loại cổng sạc',
					TITLE_FORM_EDIT: 'Sửa loại cổng sạc',
					IMAGE: 'Ảnh',
					CONNECTOR_TYPE: 'loại cổng sạc'
				}
			},
			SENSOR: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa cảm biến',
					SENSOR_TYPE: 'Loại cảm biến',
					SENSOR_NAME: 'Tên cảm biến',
					SENSOR: 'cảm biến',
					UPDATE_SENSOR_FAILED: 'Cập nhật cảm biến thất bại!',
					UPDATE_SENSOR_NAME: ' Cập nhật cảm biến',
					ADD_SENSOR_NAME: ' Tạo mới cảm biến',
					DELETE_SENSOR_NAME: ' Xóa cảm biến',
					KEY_LANGUAGE: 'Mã dịch',
				},
				MESSAGE: {
					UPDATE_SENSOR_SUCCESS: ' Cập nhật cảm biến thành công',
					ADD_SENSOR_SUCCESS: ' Tạo mới cảm biến thành công',
					DELETE_SENSOR_SUCCESS: ' Xóa cảm biến thành công',
					UPDATE_SENSOR_FAILED: 'Cập nhật cảm biến thất bại!',
					ADD_SENSOR_FAILED: 'Thêm mới cảm biến thất bại!',
					DELETE_SENSOR_FAILED: 'Xóa cảm biến thất bại!',
					SENSORTPL__NAME_NOT_VALID: 'Tên cảm biến không hợp lệ',
					SENSORTPL__ALREADY_EXIST: 'Tên cảm biến đã tồn tại',
				},
				DATA:{
					ERROR: 'Giá trị không xác định'
				}
			},
			DEVICE_ICON: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa biểu tượng thiết bị',
					TITLE_FORM_ADD: 'Thêm biểu tượng thiết bị',
					NAME_KEY: 'Tên khóa',
					ICON: 'Biểu tượng',
					ICON_SVG: 'Biểu tượng(SVG)',
					ICON_ON_MAP_SVG: 'Biểu tượng bản đồ(SVG)',
					COUNT: 'Thứ tự',
					ICON_ON_MAP: 'Biểu tượng bản đồ',
					ICONTYPE_NAME: 'Tên biểu tượng thiết bị',
					ICONTYPE: 'biểu tượng thiết bị',
				},
				MESSAGE: {
					UPDATE_ICONTYPE_SUCCESS: ' Cập nhật biểu tượng thành công',
					ADD_ICONTYPE_SUCCESS: ' Tạo mới biểu tượng thành công',
					DELETE_ICONTYPE_SUCCESS: ' Xóa biểu tượng thành công',
					UPDATE_ICONTYPE_FAILED: 'Cập nhật biểu tượng thất bại!',
					ADD_ICONTYPE_FAILED: 'Thêm mới biểu tượng thất bại!',
					DELETE_ICONTYPE_FAILED: 'Xóa biểu tượng thất bại!',
				},
			},
			TRANSPORT_TYPE: {
				GENERAL: {
					TRANSPORT_TYPE: 'Loại hình vận tải',
					TITLE_FORM_EDIT: 'Sửa loại hình vận tải',
					TITLE_FORM_ADD: 'Thêm loại hình vận tải',
					DRAG_DROP: 'Kéo thả để sắp xếp',
				},
				COLUMN: {
					DEVICE: 'Thiết bị',
					LIMITSPEDD: 'Tốc độ giới hạn',
					DEVICE_TYPE: ' Loại phương tiện',
					QNCN_CODE: 'QCVN - Loại hình hoạt động	',
				},
			},
			TRANSPORT_TYPE_QCVN: {
				GENERAL: {
					TRANSPORT_TYPE_QCVN: 'loại hình vận tải QCVN',
					TITLE_FORM_EDIT: 'Sửa loại hình vận tải QCVN',
					TITLE_FORM_ADD: 'Thêm loại hình vận tải QCVN',
				},
				COLUMN: {
					DEVICE: 'Thiết bị',
					LIMITSPEDD: 'Tốc độ giới hạn',
					DEVICE_TYPE: ' Loại phương tiện',
					QNCN_CODE: 'QCVN - Loại hình hoạt động	',
				},
			},
			LOGIN_PAGE: {
				GENERAL: {
					TITLE: 'Quản lý trang đăng nhập',
					TITLE_ADD: 'Thêm trang đăng nhập',
					TITLE_FORM_EDIT: 'Sửa trang đăng nhập',
					TITLE_FORM_ADD: 'Thêm mới trang đăng nhập',
					CHOOSE_THEME: 'Chọn giao diện đăng nhập',
					LOGIN_PAGE: 'Trang đăng nhập',
					DELETE_FORM_EDIT: 'Xóa trang đăng nhập',
				},
				COLUMN: {
					HOST: 'Host',
					WEBSITE: 'Website',
					COMPANY_NAME: 'Tên công ty',
					THEME: 'Theme',
					LOGO: 'Logo',
					IOS: 'IOS app',
					ANDROID: 'Android app',
					ICON: 'Icon',
				},
			},

			DEVICETYPE: {
				GENERAL: {
					TITLE_CROP_PICTURE: 'Chỉnh sửa ảnh',
					COMMAND: 'Lệnh gửi',
				},
				COLUMN: {
					MANUFACTURER: 'Nhà sản xuất',
					PROTOCOL: 'Giao thức',
					MODEL_NAME: 'Mã sản phẩm',
					LINK: 'Đường dẫn',
					IMAGE: 'Ảnh',
					PARAMETER_SENSOR: 'Cài đặt IO',
					EXTENSION: 'Tiện ích',
					MODEL: 'Model',
				},
			},
			SYSTEM_LOG: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa phiên bản',
					TITLE_FORM_ADD: 'Thêm phiên bản',
					VERSION: 'Phiên bản',
				},
				COLUMN: {
					VERSION_NAME: 'Tên phiên bản',
					VERSION_TYPE: 'Loại phiên bản',
					CONTENT: 'Nội dung',
				},
			},
			POI_TYPE: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa loại điểm đánh dấu',
					TITLE_FORM_ADD: 'Thêm loại điểm đánh dấu',
					VERSION: 'Phiên bản',
					POI: 'loại điểm đánh dấu',
					KEY: 'Mã dịch',
				},
				LANGUAGE: {
					OTHER: 'Khác',
					RESTAURANT: 'Nhà hàng',
					STADIUM: 'Sân vận động',
					SCHOOL: 'Trường học',
					HOSPITAL: 'Bệnh viện',
					SUPERMARKET: 'Siêu thị',
					COMPANY: 'Công ty',
					HOME: 'Nhà',
				},
			},
			TOLL_STATION: {
				COLUMN: {
					OPTION: 'Cao tốc',
					STANDARD: 'Tiêu chuẩn',
					NAME: 'trạm thu phí',
					TYPE_GEOFENCE: 'Loại vùng',
					CITY: 'Tỉnh thành',
					TYPE_STATION: 'Loại trạm',
					CHOISE_CITY: 'Chọn tỉnh thành',
					BEGIN_WORK: 'Bắt đầu hoạt động',
					END_WORK: 'Kết thúc hoạt động',
					PRICE: 'Giá tiền',
					TYPE_DEVICE: 'Loại xe',
					CIRCLE: 'Hình tròn',
					RECTANGLE: 'Hình chữ nhật',
					TAB_PRICE: 'Bảng phí',
					CURRENCY_UNIT: 'Đơn vị tiền tệ',
					COUNTRY: 'Quốc gia',
					SELECT_COUNTRY: 'Chọn quốc gia',
				},

				MESSAGE: {
					TAP_DEVICE: 'Bấm để xem tất cả loại xe',
					TAP_CURRENCY: 'Bấm để thay đổi tiền tệ',
					CREATE_TOLL_ONMAP:
						'Xin vui lòng tạo trạm thu phí trên bản đồ',
					PlEASE_COUNTRY: 'Xin vui lòng chọn tỉnh thành ',
					PLEASE_ROAD: 'Xin vui lòng chọn đường',
				},
			},

			TOLL_ROAD: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa đường thu phí',
					TITLE_FORM_ADD: 'Thêm đường thu phí',
				},
				COLUMN: {
					PROVINCE_ROAD: 'Đường tỉnh',
					HIGHWAY: 'Cao tốc',
					NATIONAL_ROAD: 'Đường quốc lộ',
				},
			},

			TOLL_SEGMENT: {
				COLUMN: {
					ROAD: 'Đường',
					START_TOLL: 'Trạm vào',
					END_TOLL: 'Trạm ra',
					IS_WAY: 'Hai chiều',
					SELECT_ROAD: 'Chọn đường',
					SELECT_TOLL_STATION: 'Chọn trạm thu phí',
					LIST_TOLL: 'Danh sách trạm',
					LIST_TOLLED: 'Trạm đã chọn',
				},
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa tuyến thu phí',
					TITLE_FORM_ADD: 'Thêm tuyến thu phí',
				},
				MESSAGE: {
					PLEASE_TOLL_STATION: 'Xin vui lòng chọn trạm thu phí',
				},
			},

			NOTIFI_GLOBAL: {
				GENERAL: {
					TIME: 'Thời gian  có hiệu lực',
					NAME: 'Thông báo hệ thống',
					TITLE_FORM_EDIT: 'Chi tiết thông báo',
					TITLE_FORM_ADD: 'Tạo thông báo',
					VERSION: 'Phiên bản',
					DELETE: 'Xóa thông báo',
				},
			},
			FEEDBACK: {
				GENERAL: {
					FEEDBACK: 'Ý kiến phản hồi',
					FEEDBACK_MANAGE: 'Quản lý ý kiến phản hồi',
					FEEDBACK_INFO: 'Thông tin ý kiến phản hồi',
					UPDATE_FEEDBACK: 'Cập nhật ý kiến phản hồi',
				},
			},
			STORAGE: {
				GENERAL: {
					FTP_SERVER: "Địa chỉ FTP",
					MINIO_SERVER: "Địa chỉ Minio",
					STORAGE_GROUP: "Nhóm quản lý dữ liệu",
					MINIO_ACCESS_KEY: "Mã xác thực minio",
					MINIO_SECRET_KEY: "Mã bí mật minio",
					MINIO_BUCKET_NAME: "Thư mục(Bucket)",
					API_ADDRESS: "Địa chỉ API",
					USERNAME: "Tên đăng nhập",
					PASSWORD: "Mật khẩu",
					TITLE_FORM_ADD: "Thêm mới nhóm dữ liệu",
					TITLE_FORM_EDIT: "Sửa nhóm dữ liệu"
				}
			},
			SYSTEM: {
				NAME: 'Tên hệ thống',
				CODE: 'Mã hệ thống',
				USERNAME: 'Tài khoản',
				ACCOUNT_NAME: 'Tên tài khoản',
				BANK_ACCOUNT: 'Số tài khoản',
				BANK_NAME: 'Tên ngân hàng',
				TITLE: 'Hệ thống',
				SEARCH: 'Tìm kiếm (Tên, mã hệ thống)',
				TITLE_FORM_ADD: 'Tạo mới hệ thống',
				TITLE_FORM_EDIT: 'Sửa hệ thống',
				TYPE: "Loại hệ thống",
				CALL_BACK: "Địa chỉ API",
				API_KEY: "API key"
			}
		},
		MANAGE: {
			GENERAL: {
				CHARGE_STATION: 'trạm sạc',
				DEVICE: 'thiết bị',
				USER: 'người dùng',
				GROUP_DEVICE: 'nhóm thiết bị',
				COMMAND: 'gửi lệnh',
				USER_ALIAS: 'bí danh người dùng',
				ROLE_ALIAS: 'bí danh vai trò',
				PROFILE: 'hồ sơ cá nhân',
				PROFILE_UPERCASE: 'Hồ sơ cá nhân',
				MAP_API_KEY: 'map API key',
				PERMISSION: 'quyền',
				ROLE: 'vai trò',
				CONNECTOR: 'cổng sạc',
				BANK: 'ngân hàng',
				TERMINAL: 'đơn vị kinh doanh',
				USER_INFO: 'Thông tin cá nhân',
				VOUCHER: 'Ví ưu đãi'
			},
			DEVICE: {
				STATUS: {
					NOT_ACTIVE: 'Không hoạt động',
					ACTIVE: 'Hoạt động',
					EXPIRED: 'Hết hạn'
				},
				SELL: {
					GENERAL: {
						SELL_DEVICE: 'Chuyển/Bán',
						TOTAL_DEVICE: 'Tổng số thiết bị',
						SELL_TO: 'Chuyển tới',
					},
				},
				SEND_COMMAND: {
					GENERAL: {
						SEND_COMMAND: 'Gửi lệnh',
						DEVICE_TYPE: 'Loại thiết bị',
						CHOOSE_DEVICE_TYPE: 'Chọn loại thiết bị',
						COMMAND_TYPE: 'Loại lệnh',
						CHOOSE_COMMAND_TYPE: 'Chọn loại lệnh',
						COMMAND_SEND: 'Lệnh gửi',
						TOTAL_DEVICE: 'Tổng số thiết bị',
						COMMAND_NAME: 'Tên lệnh',
						IMEI_DEVICE: 'Tên lệnh gửi',
					},
				},
				RENEWS: {
					GENERAL: {
						RENEWS: 'Gia hạn',
						USER_RENEWS: 'Tài khoản gia hạn',
						RENEWS_TO_DAY: 'Gia hạn đến ngày',
						TOTAL: 'Tổng (VNĐ)',
						PAY: 'Thanh toán',
						DESCRIPTIONS: 'Mô tả',
						RENEWS_CONFIRM: 'Xác nhận gia hạn',
						RENEWS_MESSAGE:
							'Bạn có chắc chắn muốn gia hạn thiết bị?',
						AMOUNT: 'Số tiền',
						USED_OLD_SERVICE_TERM: 'Sử dụng hạn dịch vụ cũ',
						MONTH:"Tháng",
						PRICE: 'Đơn giá (VNĐ/Tháng)'
					},
					VALIDATION: {
						POINTS_NOT_ENOUGH: 'Không đủ điểm để gia hạn',
						NO_DEVICES_HAVE_BEEN_SELECTED:
							'Không có thiết bị nào được chọn',
						NO_DEVICES_HAVE_BEEN_RENEWED:
							'Không có thiết bị nào được gia hạn',
					},
					MESSAGE: {
						NOTE_1: 'Gia hạn dịch vụ - 1 tháng với 1 điểm',
						NOTE_2:
							'Ngày dịch vụ sẽ được tính từ ngày hiện tại hoặc được cộng thêm vào nếu còn hợp lệ',
						NOTE_3:
							'Nếu thiết bị chưa kích hoạt bảo hành, thì sẽ tự động được kích hoạt',
						DEVICE_ERROR: 'Thiết bị không hợp lệ',
					},
					PAY: {
						UNKNOWN: 'Không xác định',
						PAID: 'Đã thanh toán',
						UNPAID: 'Chưa thanh toán',
					},
					MONTHS: {
						ADD_1_MONTH: '1 tháng',
						ADD_6_MONTHS: '6 tháng',
						ADD_1_YEAR: '1 năm',
						ADD_2_YEARS: '2 năm',
						ADD_3_YEARS: '3 năm',
					},
				},
				IMPORT: {
					GENERAL: {
						IMPORT_DEVICE: 'Thêm thiết bị',
						DOWNLOAD_TEMPLATE: 'File mẫu',
						CHOOSE_DOCUMENT: 'Chọn tài liệu',
						ADD_IMEI: 'Thêm imei',
						RESET_IMEI: 'Đặt lại imei',
						REMOVE_DATA: 'Xóa dữ liệu',
						DEVICE_TYPE: 'Loại thiết bị',
						CHOOSE_DEVICE_TYPE: 'Chọn loại thiết bị',
						CHOOSE_ICON: 'Chọn biểu tượng',
						ICON: 'Biểu tượng',
						TOTAL_IMEI: 'Tổng số imei',
						IMEI: 'Imei',
						UPLOAD_FILE: 'Đẩy file'
					},
					VALIDATION: {},
					MESSAGE: {
						NOTE_1:
							'Thêm thiết bị bởi mẫu dữ liệu hoặc nhập vào imei',
						NOTE_2: 'Imei có độ dài trong khoảng 6-20 ký tự',
					},
				},
				EDIT: {
					GENERAL: {
						EDIT_DEVICE: 'Sửa thiết bị',
						BASE: 'Cơ bản',
						ADDVANCED: 'Nâng cao',
						SENSOR: 'Cảm biến',
						CAMERA: 'Camera',
						INFO_DEVICE: 'Thông tin thiết bị',
						IMEI: 'Imei',
						USER_NAME: 'Tài khoản',
						MACHINE_CODE__NUMBER_PLATE: 'Mã máy - biển số',
						NAME_DEVICE: 'Tên thiết bị',
						ICON: 'Biểu tượng',
						CHOOSE_ICON: 'Chọn biểu tượng',
						GROUP_DEVICE: 'Nhóm thiết bị',
						CHOOSE_GROUP_DEVICE: 'Chọn nhóm thiết bị',
						ADD_DEVICE_GROUP: 'Thêm nhóm thiết bị',
						SIM_NO: 'Số SIM',
						SIM_TYPE: 'Loại SIM',
						CHOOSE_SIM_TYPE: 'Chọn loại SIM',
						DEVICE_TYPE: 'Loại thiết bị',
						CHOOSE_DEVICE_TYPE: 'Chọn loại thiết bị',
						SERVICE: 'Dịch vụ',
						SERVICE_EXPIRE: 'Hạn dịch vụ',
						ACTIVE_WARRANTY: 'Kích hoạt bảo hành',
						ACTIVE_WARRANTY_DATE: 'Ngày kích hoạt bảo hành',
						WARRANTY_EXPIRE: 'Hạn bảo hành',
						STATUS: 'Trạng thái',
						NOTE: 'Ghi chú',
						NOTE_ADMIN: 'Ghi chú (quản lý)',
						SENSOR_NAME: 'Tên cảm biến',
						SENSOR_TEMPLATE: 'Mẫu cảm biến',
						CHOOSE_SENSOR_TEMPLATE: 'Chọn mẫu cảm biến',
						PARAMETER_NAME: 'Tham số',
						CHOOSE_PARAMETER_NAME: 'Chọn tham số',
						UNIT: 'Đơn vị',
						OFFSET: 'Cân đối bù trừ',
						FORMULA: 'Công thức',
						CALIBRATION: 'Hiệu chuẩn',
						ROUND: 'Làm tròn',
						MIN: 'Tối thiểu',
						MAX: 'Tối đa',
						INCREASE: 'Tăng tối thiểu',
						DECREASE: 'Giảm tối thiểu',
						SORT_ORDER: 'Sắp xếp',
						DESCRIPTION: 'Mô tả',
						SHOW_CHART_ON_MAP: 'Hiển thị bản đồ trên map',
						INFO_VEHICEL: 'Thông tin xe',
						NUMBER_PLATE: 'Biển số',
						FRAME_NUMBER: 'Số khung',
						VIN_NUMBER: 'Số VIN',
						TRANSPORT_TYPE: 'Loại hình vận tải',
						CHOOSE_TRANSPORT_TYPE: 'Chọn loại hình vận tải',
						DRIVER: 'Lái xe',
						CHOOSE_DRIVER: 'Chọn lái xe',
						DISTANCE_OFFSET: 'Hệ số quãng đường',
						DEPARMENT: 'Bộ Giao thông vận tải',
						SEND_TO_DEPARMENT: 'Gửi dữ liệu',
						DEPARMENT_MANAGE: 'Sở Giao thông vận tải',
						CHOOSE_DEPARMENT_MANAGE: 'Chọn sở quản lý',
						ADD_DRIVER: 'Thêm lái xe',
						ADD_CAMERA: 'Thêm camera',
						NOTE_NUMBER_PLATE:
							'Vui lòng nhập biển số xe theo đúng quy chuẩn.',
						ENGINE_NUMBER: 'Số máy',
						SELL_INFO: 'Thông tin bán',
						SELLED: 'Đã bán',
						SELL_DATE: 'Ngày bán',
						UPDATE_TIME: 'Ngày cập nhật',
						SENSOR_TYPE: 'Loại cảm biến',
						CALCULATION_METHOD: 'Phương pháp tính',
						CHOOSE_CALCULATION_METHOD: 'Chọn phương pháp tính',
						NOTE_MINUTE: 'Số phút',
						EXTENSION_CONFIG: 'Cấu hình',
						EXTENSION_FUEL: 'Nhiên liệu',
						EXTENSION_ADVANCED_FEATURES: 'Tính năng nâng cao',
						CONSUMPTION_RATE: 'Định mức tiêu hao',
						TIMEOUT_NUMBER: 'Thời gian chờ GPRS',
						MINUTE: 'phút',
						SECOND: 'giây',
						LITRE: 'lít',
						MIN_SPEED: 'Lọc tốc độ tối thiểu',
						DEBUG_MODE: 'Lưu dữ liệu thô',
						EXTENSION: 'Mở rộng',
						TRANSPORT_TYPE_QCVN: 'Loại hình vận tải QCVN',
						CHOOSE_TRANSPORT_TYPE_QCVN:
							'Chọn loại hình vận tải QCVN',
						TOLL_STATION: 'Trạm thu phí',
						TIMEZONE: 'Múi giờ',
						MIN_STOP_DURATION: 'Thời gian dừng tối thiểu',
						MIN_STOP_DURATION_RANGE_PICKER:
							'Vui lòng nhập vào lớn hơn 5 giây và nhỏ hơn 60 phút',
						CORRIDOR: 'Corridor',
						DEVICE_ID_OEM: 'ID thiết bị OEM',
						ID: 'ID',
						GROUP_CAMERA: 'Nhóm camera',
						LOAD_GROUP_CAMERA: 'Làm mới nhóm camera',
						LIVESTREAM: 'Livestream',
						IMEI_OEM: "Imei oem",
						STORAGE_GROUP: 'Nhóm lưu dữ liệu(Ảnh)',
						PROVINCE: 'Thành phố/Tỉnh',
						DISTRICT: 'Quận/Huyện',
						VEHICLE_TYPE: 'Loại sạc',
						ELECTRIC_TYPE: 'Loại dòng điện',
						SERVICE_PRICE: 'Giá dịch vụ',
						DESCRIPTION_PRICE: 'Mô tả giá',
						WATTAGE: 'Công suất (kWh)',
						WATTAGE_FILTER: 'Công suất',
						DESCRIPTION_OPERATING_VOLTAGE: 'Mô tả điện áp hoạt động',
						DESCRIPTION_CONNECTOR_WATTAGE: 'Mô tả công suất cổng sạc',
						SUPPORT_PHONE_NUMBER: 'Số điện thoại hỗ trợ',
						OPEN_247: 'Mở 24/7',
						CONNECTOR: 'Cổng sạc',
						PAYMENT_SIM: 'Số SIM thanh toán',
						AMENITIES: 'Tiện nghi',
						TERMINAL: 'Đơn vị kinh doanh',
						BANK_ACCOUNT: 'Tài khoản ngân hàng',
						PULSE: 'Số tiền tương ứng với 1 xung',
						SYSTEM: "Hệ thống",
						TRAN_SYNC: "Đối soát giao dịch",
						DEVICE_CONTROL: "Điều khiển thiết bị",
						CALL_BACK: "Địa chỉ API",
						API_KEY: "API key"
					},
					MESSAGE: {
						NOTE_1:
							'Thêm thiết bị bởi mẫu dữ liệu hoặc nhập vào imei',
						NOTE_2: 'Imei có độ dài trong khoảng 6-20 ký tự',
					},
				},
				GENERAL: {
					DEVICE_HAVE_NO_DATA_PLAYBACK:
						'Thiết bị không có dữ liệu xem lại',
					DEVICE_NOT_ONLINE: 'Thiết bị không online',
					PLEASE_SELECT_TIME: 'Vui lòng chọn thời gian',
					LIST_DEVICE: 'Danh sách thiết bị',
					DEVICE: 'Thiết bị',
					IMEI: 'Imei',
					DEVICE_NAME: 'Tên thiết bị',
					DEVICE_TYPE: 'Loại thiết bị',
					SUB_ACCOUNT: 'Tài khoản con',
					MACHINE_CODE__VEHICLE_PLATE: 'Mã máy/Biển số',
					SIMNO: 'Số SIM',
					SIM_TYPE: 'Loại SIM',
					ACTIVE_DATE: 'Thời gian kích hoạt',
					ACTIVE_WARRANTY: 'Kích hoạt bảo hành',
					SERVICE_EXPIRE: 'Hạn dịch vụ',
					WARRANTY_EXPIRE: 'Hạn bảo hành',
					TIME_START: 'Thời gian bắt đầu',
					TIME_END: 'Thời gian kết thúc',
					TIME: 'Thời gian',
					EXPIRE_SERVICE: 'Hạn dịch vụ',
					EXPIRE_SOON: 'Sắp hết hạn',
					EXPIRED: 'Đã hết hạn',
					SERVICE_IS_AVAIABLE: 'Đang hoạt động',
					STATUS: 'Trạng thái',
					INACTIVE: 'Không kích hoạt',
					ACTIVE: 'Kích hoạt',
					EXPORT: 'Xuất dữ liệu',
					SELL_DEVICE: 'Chuyển/Bán',
					DISABLE: 'Vô hiệu hóa',
					ENABLE: 'Kích hoạt',
					SEND_COMMAND: 'Gửi lệnh',
					IMPORT_DEVICE: 'Thêm thiết bị',
					RENEWS: 'Gia hạn',
					ADD_DEVICE_GROUP: 'Thêm nhóm thiết bị',
					ADD_DRIVER: 'Thêm lái xe',
					ADD_CAMERA: 'Thêm camera',
					DROP_DRAP_SORT_ORDER: 'Kéo thả để sắp xếp thiết bị',
					SORT_ORDER: 'Sắp xếp',
					ADDRESS: 'Địa chỉ',
					PAYMENT_SIM: "Sim thanh toán"
				},
				MESSAGE: {
					NO_DEVICE_SELECTED: 'Chưa có thiết bị nào được chọn',
					CHOICE_PERMISSIONS: 'Chọn quyền',
				},
			},
			USER: {
				GENERAL: {
					ROLE_NAME: 'Tên quyền',
					FULL_NAME: 'Họ và tên',
					PHONE: 'Số điện thoại',
					EMAIL: 'Email',
					ACCOUNT_TYPE: 'Loại người dùng',
					PARENT_USER: 'Tài khoản cha',
					USERNAME_OR_NAME: 'Tài khoản hoặc tên',
					NEW_PASSWORD: 'Mật khẩu mới',
					OLD_PASSWORD: 'Mật khẩu cũ',
					COMFIRM_PASSWORD: 'Xác nhận mật khẩu',
					CHANGE_PASSWORD: 'Thay đổi mật khẩu',
					START_TIME: 'Thời gian bắt đầu',
					END_TIME: 'Thời gian kết thúc',
					PAGE_MAIN: 'Trang chính',
          			SUB_ACCOUNT: 'Tài khoản con',
					CUSTOMER: 'Người dùng'
        },
				MESSAGE: {
					PASS_MIN_CHAR: 'Mật khẩu phải lớn hơn 6 ký tự',
					PASSWORD_INCORRECT: 'Mật khẩu không khớp nhau',
					ADD_USER_FAILED: 'Thêm mới người dùng thất bại!',
					UPDATE_USER_FAILED: 'Cập nhật người dùng thất bại!',
					DELETE_USER_FAILED: 'Xóa người dùng  thất bại!',
					UPDATE_USER_SUCCESS: 'Cập nhật người dùng  thành công!',
					ADD_USER_SUCCESS: 'Thêm mới người dùng  thành công!',
					DELETE_USER_SUCCESS: 'Xóa người dùng  thành công!',
					RESET_PASSWORD_SUCCESS: 'Cài lại mật khẩu thành công!',
					RESET_PASSWORD_FAILED: 'Cài lại mật khẩu thất bại!',
					USER__DELETED_FAIL_HAVE_CHILD:
						'Người dùng có tài khoản con',
					USER__DELETED_FAIL_HAVE_DEVICE: 'Người dùng có thiết bị',
					USER__UPDATED_FAIL_PARENTID:
						'Không thể cập nhật tài khoản cha',
					USER__UPDATED_FAIL_NOT_CHILD:
						'Không có quyền cập nhật tài khoản khác tài khoản con',
					USER__UPDATED_ROLE_NOT_VALID:
						'Không có quyền gán vai trò Admin',
					USER__UPDATED_ROLE_NOT_FOUND:
						'Không thể cập nhật người dùng',
					USER__PARENTID_NOT_VALID:
						' Không thể chọn tài khoản con làm cha',
					UPDATE_ICONTYPE_FAILED:
						'Cập nhật biểu tượng thiết bị thất bại!',
					UPDATE_ICONTYPE_NAME: ' Cập nhật biểu tượng thiết bị',
				},

				PARAMETER: {
					TIMEZONE: 'Múi giờ',
					LANGUAGE: 'Ngôn ngữ',
					DISTANCE: 'Khoảng cách',
					VOLUME: 'Đơn vị thể tích',
					TEMPERATURE: 'Đơn vị nhiệt độ',
					WEIGHT: 'Đơn vị cân nặng',
					DATE_FORMAT: 'Ngày/tháng',
					TIME_FORMAT: 'Thời gian',
					DECIMAL_SEPARATOR: 'Số thập phân',
					FIRST_WEEKDAY: 'Ngày đầu tuần',
					DISTRIBUTOR: 'Đại lý',
					USER: 'Người dùng',
					USER_ONLY_MOTORBIKE: 'Người dùng(chỉ xe máy)',
					PARTNER: 'Đối tác'
				},

				NOTIFICATIONS: {
					BATTERY_LOW: 'Pin yếu',
					EXTERNAL_POWER_CUT: 'Ngắt nguồn bên ngoài',
					SOS: 'Cầu cứu',
					OVER_SPEED: 'Vượt quá tốc độ',
					GEOFENCE_IN: 'Vào vùng địa lý',
					GEOFENCE_OUT: 'Ra vùng địa lý',
					NONE: 'Thông tin',
					SHOCK: 'Cảnh báo va chạm',
					EXTERNAL_POWER_WEAK: 'Nguồn điện bên ngoài yếu',
					OVERSPEED: 'Vượt quá tốc độ',
					EXTERNAL_POWER_CONNECTED: 'Kết nối nguồn ngoài',
					MOVING: 'Di chuyển',
					DOOR_OPEN: 'Cửa mở',
					DOOR_CLOSE: 'Cửa đóng',
					STOP_OVERTIME: 'Dừng quá lâu',
					BATTERY_HIGHT: 'Pin đầy',
					EXTERNAL_POWER_LOW: 'Nguồn ngoài dưới mức',
					EXTERNAL_POWER_HIGHT: 'Nguồn ngoài vượt mức',
					VIN_NUMBER_CHANGE: 'Thay đổi số VIN',
					NOTIFICATION: 'Thông báo',
					TYPE: 'Loại',
					ACTIVE: 'Kích hoạt',
					INACTIVE: 'Hủy kích hoạt',
					MAP: 'Bản đồ',
					GEOFENCE: 'Vùng địa lý',
					FAVORITE: 'Yêu thích',
					DEVICE: 'Thiết bị',
					// SOS: 'Cầu cứu',
					// GEOFENCE_IN: 'Vào vùng địa lý',
					// GEOFENCE_OUT: 'Ra vùng địa lý',
					// EXTERNAL_POWER_CUT: 'Ngắt nguồn bên ngoài',
					// BATTERY_LOW: 'Pin yếu',
					VIBRATION: 'Rung động',
					// OVER_SPEED: 'Vượt quá tốc độ',
					// SHOCK: 'Cảnh báo va chạm',
					// EXTERNAL_POWER_WEAK: 'Nguồn điện bên ngoài yếu',
					// NONE: 'Thông tin',
					MOVEMEMT: 'Di chuyển',
					SCHEDULE: 'Lịch trình',
					FUEL_DRIVING: 'Nhiên liệu và lái xe',
					SAFETY: 'An toàn',
					INPUTS_OUTPUTS: 'Ra vào',
					Device: 'Thiết bị',
				},
			},
			COMMAND: {
				GENERAL: {
					SENDED: 'Gửi lúc',
					TiME_SEND: 'Thời gian gửi',
					RETURNED: 'Đã trả về',
					TIME_RETURN: 'Thời gian trả về',
					CONTENT_RETURN: 'Nội dung trả về',
					COMMAND: 'Lệnh gửi',
					RESPONSED: 'Phản hồi lúc',
					TIME_RESPONSE: 'Thời gian trả về',
					CONTENT_RESPONSE: 'Nội dung trả về',
					TITLE_FORM_ADD: 'Thêm lệnh gửi',
					TITLE_FORM_DETAIL: 'Chi tiết lệnh gửi',
					DEVICE_INFO: 'Thiết bị',
					SEND_INFO: 'Thông tin gửi',
					RESPONSE_INFO: 'Thông tin trả về',
				},
				MESSAGE: {},
			},
			DEVICE_GROUP: {
				GENERAL: {
					TITLE_FORM_EDIT: 'Sửa nhóm thiết bị',
					TITLE_FORM_ADD: 'Thêm nhóm thiết bị',
					DEVICE_GROUP: 'Nhóm thiết bị',
					ADD_DEVICE_GROUP: 'Thêm nhóm thiết bị',
					GROUP_NAME: 'Tên nhóm',
				},
				COLUMN: {
					COUNT_DEVICE: 'Số lượng thiết bị',
				},
			},
			USER_ALIAS: {
				TITLE_FORM_EDIT: 'Sửa bí danh người dùng',
				TITLE_FORM_ADD: 'Thêm bí danh người dùng',
				GENERAL: {
					BASE: 'Cơ bản',
					DEVICES: 'Thiết bị',
				},
			},
			ROLE_ALIAS: {
				TITLE_FORM_EDIT: 'Sửa bí danh vai trò',
				TITLE_FORM_ADD: 'Thêm bí danh vai trò',
			},
			ALERT_RULE: {
				GENERAL: {
					ALERT_RULE: 'Quy tắc cảnh báo',
					MANAGE_ALERT: 'Quy tắc cảnh báo',
					ALERT: 'cảnh báo',
					NAME_ALERT: 'Tên cảnh báo',
					TYPE_ALERT: 'Loại cảnh báo',
					NOTIFICATION_TEXT: 'Nội dung thông báo',
					GEOFENCE: 'Vùng địa lý',
					NOTIFICATION: 'Thông báo',
					TITLE_FORM_EDIT: 'Sửa cảnh báo',
					TITLE_FORM_ADD: 'Thêm mới cảnh báo',
					DEVICE: 'Thiết bị',
					ALWAYS_SELECT_ALL_DEVICE: 'Luôn chọn tất cả thiết bị',
					ALWAYS_SELECT_ALL_GEOFENCE: 'Luôn chọn tất cả vùng',
					ALWAYS_SELECT_ALL_SENSOR: 'Luôn chọn tất cả cảm biến',
					SENSOR: 'Cảm biến',
				},
				TYPE_GROUP_ALERT: {
					MOVEMEMT: 'Di chuyển',
					SCHEDULE: 'Lịch trình',
					FUEL_DRIVING: 'Nhiên liệu và lái xe',
					SAFETY: 'An toàn',
					INPUTS_OUTPUTS: 'Ra vào',
					DEVICE: 'Thiết bị',
				},
				PARAMS_VALUE: {
					OVER_SPEED: 'Vượt quá tốc độ',
				},
				NOTIFICATION: {
					CONTENT: 'Nội dung nhận thông báo',
					TIME_NOTIFI: 'Thời gian nhận thông báo',
					DAY: 'Ngày',
					TO: 'Đến',
					FROM: 'Từ',
					TIME: 'Thời gian',
					OPERATION: 'Hoạt động',
					OPERATION_ALWAYS: 'Luôn hoạt động',
					OPERATION_TIME: 'Hoạt động trong khoảng',
					FROM_DAY: 'Từ ngày',
					TO_DAY: 'Đến ngày',
					SMS: 'Sms thông báo',
					SMS_LABEL:
						'(Danh sách điện thoại ngăn cách bởi dấu "," hoặc ";")',
					EMAIL: 'Email thông báo',
					EMAIL_LABEL:
						'(Danh sách email ngăn cách bởi dấu "," hoặc ";")',
					PUSH: 'Nhận thông báo',
					PUSH_TOOLTIP: 'Nhận thông báo qua mobile app ',
					EMERGENCY: 'Thông báo khẩn cấp',
					EMERGENCY_TOOLTIP: 'Thông báo khẩn cấp',
					NOTIFICATION: 'Thông báo',
					NO_FOUND_NOTI: 'Không có thông báo mới',
					MARK_ALL_READ: 'Đánh dấu tất cả là đã đọc',
					VIEW_ALL: 'Xem tất cả',
					MARK_AS_READ: 'Đánh dấu là đã đọc',
					MARK_AS_UNREAD: 'Đánh dấu là chưa đọc',
					CLICK_HERE_FOR_VIEW_DETAIL: 'Bấm vào để xem chi tiết',
					SEE_MORE: 'Xem thêm',
					TIME_RANGE: 'Khoảng thời gian',
				},
				GEOFENCE: {
					OUT: 'Ngoài vùng',
					IN: 'Trong vùng',
					NONE: 'Bỏ qua',
					OBD: 'OBD',
				},
				TYPE_ALERT: {
					MOVEMEMT: 'Di chuyển',
					SCHEDULE: 'Lịch trình',
					FUEL_DRIVING: 'Nhiên liệu và lái xe',
					SAFETY: 'An toàn',
					INPUTS_OUTPUTS: 'Ra vào',
					Device: 'Thiết bị',
					BATTERY_LOW: 'Pin yếu',
					EXTERNAL_POWER_CUT: 'Ngắt nguồn bên ngoài',
					SOS: 'Cầu cứu',
					OVER_SPEED: 'Vượt quá tốc độ',
					GEOFENCE_IN: 'Vào vùng địa lý',
					GEOFENCE_OUT: 'Ra vùng địa lý',
					NONE: 'Thông tin',
					SHOCK: 'Cảnh báo va chạm',
					EXTERNAL_POWER_WEAK: 'Nguồn điện bên ngoài yếu',
					OVERSPEED: 'Vượt quá tốc độ',
					EXTERNAL_POWER_CONNECTED: 'Kết nối nguồn ngoài',
					EXTERNAL_POWER_CONNECT: 'Kết nối nguồn ngoài',
					MOVING: 'Di chuyển',
					DOOR_OPEN: 'Cửa mở',
					DOOR_CLOSE: 'Cửa đóng',
					STOP_OVERTIME: 'Dừng quá lâu',
					BATTERY_HIGHT: 'Pin đầy',
					EXTERNAL_POWER_LOW: 'Nguồn ngoài dưới mức',
					EXTERNAL_POWER_HIGHT: 'Nguồn ngoài vượt mức',
					EXTERNAL_POWER_HIGH: 'Nguồn ngoài vượt mức',
					VIN_NUMBER_CHANGE: 'Thay đổi số VIN',
					TASK_NORMAL: 'Nhắc nhở',
					TRIP_DEVIATION: 'Đi lệch chuyến',
					THIEVES_FUEL: 'Trộm nhiên liệu',
					COMMING_GEOFENCE: 'Sắp đến vùng',
					DISTANCE: 'Khoảng cách cảnh báo',
					LOST_FUEL: 'Nhiên liệu thất thoát',
					MAINTENANCE_BY_DISTANCE: 'Bảo dưỡng theo quãng đường',
					MAINTENANCE_BY_HOURS: 'Bảo dưỡng theo giờ',
					CAMERA_COVER_ALARM: 'Cảnh báo che camera',
					IO1: 'IO1',
					IO2: 'IO2',
					IO3: 'IO3',
					IO4: 'IO4',
					IO5: 'IO5',
					IO6: 'IO6',
					IO7: 'IO7',
					IO8: 'IO8',
					IO9: 'IO9',
					IO10: 'IO10',
					IO11: 'IO11',
					IO12: 'IO12',
					IO13: 'IO13',
					IO14: 'IO14',
					IO15: 'IO15',
					IO16: 'IO16',
					EMERGENCY_BUTTON_ALARM: 'Nút báo động khẩn cấp',
					ACC_ALARM: 'Báo động ACC',
					ENTER_OR_EXIT_FENCE: 'Ra vào vùng',
					EYES_CLOSED: 'Nhắm mắt',
					COLLISION_ALARM: 'Báo động va chạm',
					DRIVER_FATIGUE: 'Cảnh báo lái xe mệt mỏi',
					NO_DRIVER: 'Không có lái xe',
					PHONE_DETECTION: 'Phát hiện điện thoại',
					SMOKING: 'Hút thuốc',
					DISTRACTION: 'Mất tập trung',
					LANE_DEPARTURE: 'Sai làn',
					CONTINUOUS_OVERSPEED: 'Liên tục quá tốc độ',
					DISTANCE_TOO_CLOSE: 'Khoảng cách quá gần',
					PEDESTRIAN_DETECTION: 'Phát hiện người đi bộ',
					YAWNING: 'Ngáp',
					LEFT_SIDE_BSD: 'Bên trái BSD',
					RIGHT_SIDE_BSD: 'Bên phải BSD',
					LINE_FENCE_OVERSPEED: 'Đi quá tốc độ',
					HARSH_ACCELERATION: 'Tăng tốc đột ngột',
					HARSH_BRAKE: 'Phanh đột ngột',
					HARSH_LEFT_TURN: 'Rẽ trái đột ngột',
					HARSH_RIGHT_TURN: 'Rẽ phải đột ngột',
					OVERTIME_DRIVING_WARN: 'Cảnh báo lái xe quá thời gian',
					OVERTIME_DRIVING_ALARM: 'Thông báo lái xe quá thời gian',
					IGNITION: 'Nổ máy',
					IGNITION_STOP: 'Dừng xe nổ máy',
					IGNITION_ON: 'Bật máy',
					IGNITION_OFF: 'Tắt máy',
					FUEL_LEAK: 'Rút xăng',
					FUEL_ADD: 'Đổ xăng',
					ENTER_GPS_DEAD: 'Mất GPS',
					EXIT_GPS_DEAD: 'Có GPS',
					TEMPERATURE_HIGH: 'Nhiệt độ tăng cao',
					TEMPERATURE_LOW: 'Nhiệt độ giảm thấp',
					TEMPERATURE: "Nhiệt độ"
				},
				GROUP_TYPE_ALERT: {
					DEVICE: 'Thiết bị',
					ACTION: 'Hoạt động',
					IN_OUT: 'Ra vào',
					OBD: 'OBD',
					NORMAL: 'Cơ bản',
				},
				PARAMS: {
					INTERVAL: 'Lặp lại sau',
					TIME: 'Thời gian',
					SPEED: 'Tốc độ',
					PERCENT: 'Phần trăm',
					VALUE: 'Giá trị',
					POWER_VOL: 'Điện áp',
				},
				DAY: {
					MO: 'T2',
					TU: 'T3',
					WE: 'T4',
					TH: 'T5',
					FR: 'T6',
					SA: 'T7',
					SU: 'Cn',
				},
			},
			TASK_REMIND: {
				GENERAL: {
					TASK_REMIND: 'Nhắc nhở',
					NORMAL: 'nhắc nhở',
					TYPE_TASK: 'Loại nhắc nhở',
					TITLE_FORM_EDIT: 'Sửa nhắc nhở',
					TITLE_FORM_ADD: 'Thêm mới nhắc nhở',
					TITLE_FORM_DELETE: 'Xóa nhắc nhở',
					TIME_ON_DAY: 'Thời gian trong ngày (hh:mm:ss)',
					REPEAT: 'Lặp lại',
					TIME: 'Thời gian nhận',
				},
				PARAMS_VALUE: {
					ODOMETER: 'Công tơ mét (km)',
					ODOMETER_BEFORE: 'Bảo trì lần trước (km)',
					ODOMETER_AFTER: 'Bảo trì tiếp theo (km)',
					DAY_PASS: 'Ngày gần đây nhất',
					DAY_NEXT: 'Ngày bảo trì tiếp theo',
					TOTAL_HOURS: 'Tổng số giờ (H)',
					HOURS_BEFORE: 'Giờ bảo trì cuối cùng (H)',
					HOURS_AFTER: 'Giờ bảo trì tiếp theo (H)',
				},
			},
			ALERT: {
				GENERAL: {
					MESSAGE_CONTENT: 'Nội dung thông báo',
					MANAGE_ALERT: 'Thông báo',
					TITLE_FORM_DETAIL: 'Chi tiết thông báo',
					READED: 'Đã đọc',
					UNREAD: 'Chưa đọc',
					OBJECT: 'Đối tượng',
					CLICK_DOWNLOAD: 'Bấm để tải xuống',
					GENERATION_DONE: 'Báo cáo đã tạo xong',

					TYPE_ALERT: 'Loại thông báo',
				},
			},
			DRIVER: {
				GENERAL: {
					DRIVER: 'lái xe',
					NAME: 'Tên',
					PHONE: 'Số điện thoại',
					LICENSE_NUMBER: 'Giấy phép lái xe',
					EMAIL: 'Email',
					DESCRIPTION: 'Mô tả',
					BEGIN_DATE: 'Ngày cấp phép',
					EXPRIE_DATE: 'Ngày hết hạn',
					DRIVER_CODE: 'Mã',
					ADDRESS: 'Địa chỉ',
					TITLE_FORM_ADD: 'Thêm mới lái xe',
					TITLE_FORM_EDIT: 'Sửa lái xe',
				},
				GROUP: {
					INFO: 'Thông tin',
					NOTE: 'Ghi chú',
					LICENSE: 'Giấy phép',
				},
			},
			GEOFENCE: {
				GENERAL: {
					NAME: 'Tên',
					GEOFENCE: 'vùng',
					GEOFENCE_MANAGE: 'Quản lý vùng',
					SEARCH_GEOFENCE: 'Tìm kiếm vùng',
					GEOFENCES: 'Vùng',
				},
				TOOL: {
					INFO_GEOFENCE: 'Thông tin vùng',
					NAME: 'Tên',
					DESCRIPTION: 'Mô tả',
					RADIUS: 'Bán kính',
					LATITUDE: 'Vĩ độ',
					LONGITUDE: 'Kinh độ ',
					COLOR: 'Màu viền',
					OPACITY: 'Độ mờ viền',
					FILL_COLOR: 'Màu nền',
					FILL_OPACITY: 'Độ mờ nền',
					SHOW__HIDE_DEVICES: 'Bật/tắt danh sách thiết bị',
					PLEASE_CREATE_GEOFENCE: 'Vui lòng tạo vùng trên bản đồ',
					PLEASE_CHOOSE_GEOFENCE: 'Vui lòng chọn nhóm vùng địa lý'
				},
			},
			GEOFENCE_GROUP: {
				GENERAL: {
					NAME: 'Tên',
					GEOFENCE_GROUP: 'nhóm vùng',
					TITLE_FORM_EDIT: 'Sửa nhóm vùng',
					TITLE_FORM_ADD: 'Thêm mới nhóm vùng',
					COUNT: 'Số lượng',
					CHOISE: 'Chọn nhóm',
					GEOFENCE_NOT_FOUND: 'Không tìm thấy vùng'
				},
			},
			POI: {
				GENERAL: {
					NAME: 'điểm đánh dấu',
					TYPE: 'Loại điểm đánh dấu',
					TYPE_POI: 'Chọn loại',
					ACTIVE: 'Kích hoạt',
					INACTIVE: 'Hủy kích hoạt',
				},
				MESSAGE: {
					NOT_MARKET: 'Xin vui lòng tạo điểm đánh dấu trên bản đồ',
					NOT_TYPE: 'Xin vui lòng chọn loại điểm đánh dấu',
				},
			},
			REPORT_SCHEDULE: {
				GENERAL: {
					REPORT_SCHEDULE: 'lập lịch báo cáo',
					TITLE_FORM_EDIT: 'Sửa lập lịch báo cáo',
					TITLE_FORM_ADD: 'Thêm lập lịch báo cáo',
					TITLE_FORM_DELETE: 'Xóa lập lịch',
					SELECT_REPORTS: 'Chọn báo cáo',
				},
				COLUMN: {
					NAME: 'Tên',
					TYPE: 'Loại báo cáo',
					FORMAT: 'Định dạng',
					DEVICE: 'Thiết bị',
					SCHEDULE: 'Lập lịch',
					SEND_TO_EMAIL: 'Gửi đến email',
					DAILY: 'Hằng ngày',
					WEEKLY: 'Hằng tuần',
					DAILY_WEEKLY: 'Hằng ngày, hằng tuần',
				},
				MESSAGE: {
					TYPE_REPORT: 'Vui lòng chọn loại báo cáo',
					WEEKLY_MESSAGE: 'Báo cáo sẽ được gửi vào thứ hai hằng tuần',
				},
			},
			ACTIVITY_LOG: {
				GENERAL: {
					BASIC: 'Nền tảng',
					USER_AGENT: 'Đăng nhập',
					ACTION_TYPE: 'Loại chức năng',
					OBJECT_TYPE: 'Đối tượng',
					OBJECT_NAME: 'Tên đối tượng',
					EVENT_DESC: 'Mô tả sự kiện',
					DETAIL: 'Chi tiết',
				},

				ACTIONS: {
					DELETE: 'Xóa',
					CREATE: 'Tạo mới',
					UPDATE: 'Cập nhật',
					EXTEND: 'Mở rộng',
					MOVE: 'Di chuyển',
					EXTENSION: 'Gia hạn',
					CHANGE_STATUS: 'Thay đổi trạng thái',
					SELL: 'Bán',
					SORT_ORDER: 'Thứ tự sắp xếp',
					UPDATE_PROFILE: 'Cập nhật hồ sơ',
					UPDATE_PATH_CHILD: 'Cập đường dẫn con',
					LOGIN: 'Đăng nhập',
					CREATE_ALIAS: 'Tạo mới bí danh',
					UPDATE_ALIAS: 'Cập nhật bí danh',
					DELETE_ALIAS: 'Xóa bí danh',
					STREAM_CAMERA: 'Xem trực tiếp',
					VIEW_CAMERA: 'Xem camera',
				},
				OBJECT: {
					DEVICES: 'Thiết bị',
					DEVICE_ICON: 'Biểu tượng thiết bị',
					DEVICE_GROUP: 'Nhóm thiết bị',
					PERMISSION: 'Quyền',
					CARD: 'Thẻ',
					USER_SESSION: 'Phiên người dùng',
					LOGIN_PAGE: 'Trang đăng nhập',
					USER: 'Người dùng',
					SENSOR_TEMPLATE: 'Mẫu cảm biến',
					DEVICE_TYPES: 'Loại thiết bị',
					ROLES: 'Vai trò',
					SIM_TYPE: 'Loại SIM',
					TRANSPORT_TYPE: 'Loại hình vận tải',
					ROLE_ALIAS: 'Bí danh vai trò',
					USER_SETTING: 'Cấu hình người dùng',
					POI_TYPE: 'Loại điểm đánh dấu',
					POI: 'Điểm đánh dấu',
					TOLL_STATION: 'Trạm thu phí',
					LATITUDE: 'Vĩ độ',
					LONGITUDE: 'Kinh độ',
					CAMERA: 'Camera',
					SENSOR: 'Cảm biến',
					GEOFENCE: 'Vùng',
					MAP_KEY: 'Từ khóa',
				},
				EVENT: {
					REMOVE_PERMISSION: 'Xóa quyền',
					ADD_PERMISSION: 'Thêm quyền',
					FROM: 'từ',
					TO: 'sang',
					CHANGE: 'Thay đổi',
					CREATE: 'Tạo mới',
					DELETE: 'Xóa',
					LOGIN: 'đăng nhập',
					PAGE: 'Trang',
				},
			},
			MAP_API_KEY: {
				API_NAME: 'Tên',
				API_KEY: 'API key',
				TYPE: 'Loại',
				GOOGLE_TYPE: 'Google Map API key',
				API_KEY_OWNER: 'Sở hữu',
				ACTIVE: 'Kích hoạt',
				INACTIVE: 'Hủy kích hoạt',
				USER_USE: 'Người dùng',
				CHOOSE_USER_USE: 'Chọn người dùng',
				SEARCH_USER_USE: 'Tìm kiếm người dùng',
				SELECTED_USER_USE: 'Người dùng đã chọn',
				DESCRIPTION: 'Mô tả',
				RESET: 'Đặt lại',
				ALREADY_EXIST_MAP_USERS: 'Người dùng được chọn đã tồn tại',
			},
			HELP: {
				GENERAL: {
					SYSTEM_CHANGE: 'Nhật ký thay đổi hệ thống',
					DOCS_PAGE: 'Hướng dẫn sử dụng',
					PRIVATE_POLICTY: 'Chính sách bảo mật',
				},
				OBJECT: {
					DEVICES: 'Thiết bị',
					DEVICE_ICON: 'Biểu tượng thiết bị',
					DEVICE_GROUP: 'Nhóm thiết bị',
					PERMISSION: 'Quyền',
					CARD: 'Thẻ',
					USER_SESSION: 'Phiên người dùng',
					LOGIN_PAGE: 'Trang đăng nhập',
					USER: 'Người dùng',
					SENSOR_TEMPLATE: 'Mẫu cảm biến',
					DEVICE_TYPES: 'Loại thiết bị',
					BASIC: 'Nền tảng',
					USER_AGENT: 'Đăng nhập',
					ACTION_TYPE: 'Loại chức năng',
					OBJECT_TYPE: 'Đối tượng',
					OBJECT_NAME: 'Tên đối tượng',
					EVENT_DESC: 'Mô tả sự kiện',
					DETAIL: 'Thông tin chi tiết',
					ROLES: 'Vai trò',
					SIM_TYPE: 'Loại SIM',
					TRANSPORT_TYPE: 'Loại hình vận tải',
				},

				ACTIONS: {
					DELETE: 'Xóa',
					CREATE: 'Tạo mới',
					UPDATE: 'Cập nhật',
					EXTEND: 'Mở rộng',
					MOVE: 'Di chuyển',
					EXTENSION: 'Gia hạn',
					CHANGE_STATUS: 'Thay đổi trạng thái',
					SELL: 'Bán',
					SORT_ORDER: 'Thứ tự sắp xếp',
					UPDATE_PROFILE: 'Cập nhật hồ sơ',
					UPDATE_PATH_CHILD: 'Cập đường dẫn con',
					LOGIN: 'Đăng nhập',
				},
				EVENT: {
					REMOVE_PERMISSION: 'Xóa quyền',
					ADD_PERMISSION: 'Thêm quyền',
					FROM: 'từ',
					TO: 'sang',
					CHANGE: 'thay đổi',
					CREATE: 'tạo mới',
					DELETE: 'xóa',
					LOGIN: 'đăng nhập',
					PAGE: 'Trang',
				},
			},
			MESSAGE: {
				COLUMN: {
					TITLE: 'Tiêu đề',
					COUNT_USER: 'Số lượng người nhận',
					CONTENT: 'Nội dung',
				},
				RECEIVE: {
					MESSAGE: 'Tin nhắn',
					NOT_FOUND: 'Không có tin nhắn mới',
					DELETE_ALL: 'Xóa tất cả',
					DELETE: 'Xóa tin nhắn',
					DELETE_MESSAGE: 'Bạn có chắc muốn xóa tất cả tin nhắn',
					MARK_ALL: 'Bạn có chắc đánh dấu tất cả tin nhắn',
					MARK: 'Đánh dấu tin nhắn',
				},
				SEND: {
					MESSAGE: 'Gửi tin nhắn',
					DETAIL: 'Chi tiết tin nhắn',
					CUSTOMER: 'Khách hàng của tôi',
					SEND_TO: 'Gửi tới',
					CHARACTER: 'Ký tự',
					LIMIT_CHARACTER:
						'Nội dung tin nhắn không được quá 300 ký tự.',
				},
			},
			TRIP: {
				GENERAL: {
					TITLE_FORM_ADD: 'Thêm chuyến đi',
					TITLE_FORM_EDIT: 'Sửa chuyến đi',
					NAME: 'chuyến đi',
				},
				COLUMN: {
					LIST: 'Danh sách vùng',
				},
			},
			BOOKING: {
				INFO: 'Thông tin đặt lịch',
				CONFIRM_INFO: 'Xác nhận thông tin',
				CONFIRM: 'Xác nhận',
				NEXT: 'Tiếp',
				SUCCESS: 'Đặt lịch thành công',
				FAIL: 'Đặt lịch thất bại',
				NEW: 'Đặt lịch mới',
				RESULT: 'Kết quả',
				CHOOSE_CONNECTOR: 'Chọn cổng sạc',
				PREVIOUS: 'Quay lại',
				PICK_DATE: 'Chọn ngày',
				CHARGING_HOURS: 'Số giờ sạc',
				HOUR: 'Giờ',
				CHOOSE_TIME_FRAME:'Chọn khung giờ',
				CONNECTOR: 'Cổng sạc',
				BOOKED_AT:'Ngày đặt',
				TIME_SLOT:'Khung giờ',
				CONNECTOR_NAME: 'Tên cổng sạc',
				WATTAGE:'Công suất (kWh)',
				ELECTRIC_TYPE: 'Loại dòng điện',
				VEHICLE_TYPE: 'Sạc cho loại xe',
				ELECTRIC_PRICE: 'Giá điện (VNĐ/kWh)',
				PARKING_PRICE: 'Phí đỗ xe (VNĐ/h)',
				OCCUPIED_CHARGE_PRICE: 'Phí chiếm dụng (VNĐ/h)',
				ADDRESS: 'Địa chỉ',
				CHARGE_TIME: 'Thời gian sạc',
				PROVISIONAL_COSTS:'Chi phí tạm tính (VNĐ)',
				MINUTE: 'Phút',
				CHARGER_NAME: 'Tên trạm sạc',
				START_TIME: 'Thời gian bắt đầu',
				END_TIME: 'Thời gian kết thúc',
				STATUS: 'Trạng thái',
				BOOKED: 'Đã đặt',
				CREATED: 'Chưa xác nhận',
				CANCELED: 'Hủy',
				COMPLETED: 'Hoàn thành',
				CHARGING: 'Đang sạc',
				EXPIRED: 'Hết hạn',
				COMFIRM_MESSAGE: 'Bạn muốn xác nhận lịch đặt này?',
				CANCEL_MESSAGE: 'Bạn có chắc muốn hủy lịch đặt này?',
				CONFIRM_TITLE: 'Xác nhận đặt lịch',
				CANCEL_TITLE: 'Hủy đặt lịch',
				CONNECTOR_TYPE: 'Loại cổng sạc',
				DETAIL_TITLE: 'Chi tiết đặt lịch',
				VALIDATE_TIME_START: 'Thời gian bắt đầu nằm ngoài khung giờ đã chọn',
				SELECT_TIME: 'Chọn thời gian',
				DEPOSIT: 'Tiền cọc (VNĐ)',
				IMEI: 'Imei'
			},
			RFID_TAG: {
				TAG_ID: 'Mã thẻ',
				TAG_NAME: 'Tên thẻ',
				EXPIRY_DATE: 'Ngày hết hạn',
				DESCRIPTION: 'Mô tả',
				USERNAME: 'Tài khoản sở hữu',
				TITLE: 'Thẻ RFID',
				UNKNOWN: 'Không xác định',
				COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa thẻ RFID này?',
				TITLE_FORM_ADD: 'Thêm thẻ RFID',
				TITLE_FORM_EDIT: 'Sửa thẻ RFID',
			},
			SERVICE_PRICE: {
				NAME: 'Tên',
				ELECTRICITY_PRICE: 'Giá điện',
				PARKING_PRICE: 'Giá đỗ xe',
				DESCRIPTION: 'Mô tả',
				OCCUPIED_CHARGER_PRICE: 'Giá chiếm dụng đầu sạc',
				TITLE: 'Giá dịch vụ',
				COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa giá dịch vụ này?',
				TITLE_FORM_ADD: 'Tạo mới giá dịch vụ',
				TITLE_FORM_EDIT: 'Sửa giá dịch vụ',
				TITLE_DELETE: 'Xóa giá dịch vụ',
				MINUTE: 'Phút',
				PRICE: 'Giá tiền (VNĐ/Tháng)',
				PRICE_BY_TIME: 'Giá tiền theo thời gian',
				NO_DATA: 'Không có dữ liệu',
				DISCOUNT_HOUR: 'Khung giờ giảm giá',
				START_TIME: 'Thời gian bắt đầu (HH:mm)',
				END_TIME: 'Thời gian kết thúc (HH:mm)',
				DISCOUNT: 'Chiết khấu (%)',
				START_TIME_INVALID: 'Thời gian bắt đầu chưa đúng định dạng HH:mm',
				END_TIME_INVALID: 'Thời gian kết thúc chưa đúng định dạng HH:mm',
			},
			WALLET_TRANSACTION: {
				TRANSACTION_TYPE: 'Loại giao dịch',
				AMOUNT: 'Số tiền',
				STATUS: 'Trạng thái',
				BOOKING: 'Đặt lịch',
				SUCCESS: 'Thành công',
				REFUND: 'Hoàn lại',
				FAIL: 'Thất bại',
				PENDING: 'Đang xử lí',
				CANCEL_BOOKING: 'Hủy đặt lịch',
				EXTEND: 'Gia hạn thiết bị',
				TOP_UP: 'Nạp tiền'
			},
			WALLET: {
				TYPE: 'Loại ví',
				USERNAME: 'Tài khoản',
				DOMAIN: 'Tên miền',
				NAME: 'Tên ví',
				PASSWORD: 'Mật khẩu',
				PORT: 'Cổng',
				SEARCH_TEXT: 'Tìm kiếm (Tên, tài khoản, tên miền)',
				TEXT: 'ví',
				TITLE_FORM_ADD: 'Tạo mới ví',
				TITLE_FORM_EDIT: 'Sửa ví'
			},
			BANK: {
				ACCOUNT_NAME: 'Tên tài khoản',
				BANK_ACCOUNT: 'Số tài khoản',
				BANK_NAME: 'Tên ngân hàng',
				SEARCH_TEXT: 'Tìm kiếm (Tài khoản, ngân hàng)',
				WALLET: 'Ví',
				TEXT: 'Ngân hàng',
				TITLE_FORM_ADD: 'Tạo mới ngân hàng',
				TITLE_FORM_EDIT: 'Sửa ngân hàng',
				TRANS_FEE: 'Phí giao dịch (%)',
				SECRET_KEY: 'Mã hoàn tiền'
			},
			TERMINAL: {
				NAME: 'Tên',
				CODE: 'Khóa',
				TOTAL_DEVICE: 'Số lượng thiết bị',
				TITLE_FORM_ADD: 'Tạo mới đơn vị kinh doanh',
				TITLE_FORM_EDIT: 'Sửa đơn vị kinh doanh'
			},
			VOUCHER: {
				NAME: 'Tên ví ưu đãi',
				PARTNER_NAME: 'Tên đối tác',
				USERNAME: 'Tên người dùng',
				AMOUNT: 'Số dư (VNĐ)',
				SEARCH_TEXT: 'Tìm kiếm',
				PERSONAL: 'Cá nhân',
				MONEY_TRANSFER_ACCOUNT: 'Tài khoản chuyển tiền',
				RECEIVING_ACCOUNT: 'Tài khoản nhận tiền',
				TRANSFER_AMOUNT: 'Số tiền chuyển'
			}
		},
		UTILITIES: {
			CARD_SERVICE: {
				GENERAL: {
					TITLE_FORM_ADD: 'Tạo mã mới',
					CARD: 'thẻ',
					USED: 'Đã sử dụng',
					NOT_USED: 'Chưa sử dụng',
					STATISTICS: 'Thống kê',
					MONTH: 'tháng',
				},
				COLUMN: {
					NAME_CARD: 'Mã thẻ',
					DENOMINATIONS: 'Mệnh giá',
					QUANTITY: 'Số lượng',
				},
			},
		},
		TECHNICAL_SUPPORT: {
			IMEI: 'IMEI',
			DEBUG: {
				DEBUG: 'Raw log',
				RAW_DATA: 'Dữ liệu thô',
			},
			SIM_INFO: {
				SENDER: 'Người gửi',
				DEVICE_NAME: 'Tên thiết bị',
				NUMBER_PLATE: 'Biển số',
				SIM_NO: 'Số SIM',
				CONTENT: 'Nội dung',
			},
			DEVICE_LOGIN: {
				SOCKET_VERSION: 'Phiên bản socket',
				SOCKET_IP: 'IP Socket',
				PORT: 'Port',
				FIRMWARE_VERSION: 'Phiên bản firmware',
				LOGIN_DATA: 'Dữ liệu đăng nhập',
			},
			LOST_SIGNAL: {
				TYPE: 'Loại mất tín hiệu',
				LOST_GPRS: 'Mất GPRS',
				LOST_GPS: 'Mất GPS',
				DEVICE_TYPE: 'Loại thiết bị',
				DEVICE_NAME: 'Tên thiết bị',
				TIME: 'Thời gian',
				TIME_LOST_GPS: 'GPS (phút)',
				TIME_LOST_GPRS: 'GPRS (phút)',
				TYPE_NOT_EMPTY: 'Loại tín hiệu không để trống',
				TIME_NOT_EMPTY: 'Thời gian không để trống',
				NUMBER_PLATE: 'Biển số',
			},
		},
		POINTS: {
			MANAGE: {
				GENERAL: {
					POINT_MANAGE: 'Quản lý điểm',
					ADD_POINT: 'Thêm điểm',
					USER_NAME: 'Tên tài khoản',
					POINT: 'Điểm',
					ACCOUNT_INFORMATION_SEND: 'Thông tin tài khoản chuyển',
					CURRENT_POINT: 'Điểm hiện tại',
					ACCOUNT_INFORMATION_RECEIVE: 'Thông tin tài khoản nhận',
					TRANSACTION_INFORMATION: 'Thông tin giao dịch',
					AMOUNT_POINT: 'Số điểm',
					PAY: 'Thanh toán',
					DATE_PAY: 'Ngày thanh toán',
					BILL_IMG: 'Ảnh chụp hóa đơn',
					DESCRIPTION: 'Ghi chú',
					TRANSACTION_MANAGE: 'Quản lý giao dịch',
					TYPE: 'Loại',
					DEVICE_IMEI: 'IMEI thiết bị',
					TRANSACTION_HISTORY: 'Lịch sử giao dịch',
					CANCEL_TRANSACTION: 'Hủy giao dịch',
					REQUEST_CANCEL_TRANSACTION: 'Yêu cầu hủy giao dịch',
					EDIT_TRANSACTION: 'Cập nhật giao dịch',
					DETAIL_TRANSACTION: 'Chi tiết giao dịch',
					RENEWAL_MANAGE: 'Quản lý gia hạn',
					AMOUNT: 'Sô tiền',
					RENEWAL_HISTORY: 'Lịch sử gia hạn',
					TOTAL_DEVICE_RENEWS: 'Tổng thiết bị gia hạn',
					COUNT_DEVICE_RENEWS: 'Số thiết bị gia hạn',
					ADD_POINTS: 'Thêm điểm',
					MOVE_POINTS: 'Chuyển điểm',
					RENEWS: 'Gia hạn',
					COUNT_TRANSACTION: 'Số giao dịch',
				},
				VALIDATE: {
					NOT_ENOUGH_POINTS: 'Số điểm hiện có không đủ',
					TYPE_INVALID: 'Loại hoặc trạng thái giao dịch không hợp lệ',
				},
			},
			TYPE: {
				ADD: 'Nhận',
				MOVE: 'Chuyển',
				RENEWS: 'Gia hạn',
				ALL: 'Tất cả',
			},
			PAY: {
				UNKNOWN: 'Không xác định',
				PAID: 'Đã thanh toán',
				UNPAID: 'Chưa thanh toán',
				CANCEL: 'Hủy',
				REQUEST_CANCEL: 'Yêu cầu huỷ',
			},
			SEARCH: {
				ACCOUNT_OR_NAME: 'Tài khoản hoặc tên',
				PHONE: 'Số điện thoại',
				EMAIL: 'Email',
				SUB_ACCOUNT: 'Tài khoản con',
				CHOOSE_TYPE: 'Chọn loại',
				ACCOUNT_TRANSACTION: 'Tài khoản giao dịch',
				DATE: 'Ngày',
				CHOOSE_PAY_STATUS: 'Trạng thái thanh toán',
				CHOOSE_SERVER: 'Chọn máy chủ',
			},
		},
		REPORT: {
			REPORTS: {
				GENERAL: {
					REPORTS: 'Báo cáo',
					SCHEDULE: 'Lập lịch báo cáo',
					ADD_REPORT: 'Thêm báo cáo',
					REMOVE_REPORT: 'Ẩn báo cáo',
					CHOISE_REPORT: 'Chọn báo cáo',
					HIDE_REPORT: 'Ẩn báo cáo',
					REPORT_FAVORITE: 'Báo cáo quan tâm',
					QCVN: 'Bộ giao thông vận tải',
					ADD_FAVORITE: 'Thêm báo cáo quan tâm',
					REMOVE_FAVORITE: 'Ẩn báo cáo quan tâm',
				},
			},
			TRANSACTION_HISTORY: {
				DEVICE_NAME: 'Tên thiết bị',
				AMOUNT: 'Số tiền (VNĐ)',
				BANK_ACCOUNT: 'Số tài khoản',
				BANK_NAME: 'Ngân hàng',
				IMEI: 'Mã thiết bị',
				SYSTEM: 'Hệ thống',
				TERMINAL_NAME: 'Đơn vị kinh doanh',
				TIME: 'Thời gian',
				USER_BANK_NAME: 'Tên tài khoản',
				TOTAL_AMOUNT: 'Tổng số tiền:'
			},
			DEVICE_STATUS: {
				IMEI: 'Mã thiết bị',
				START_TIME: 'Thời gian bắt đầu',
				END_TIME: 'Thời gian kết thúc',
				VERSION: 'Phiên bản',
				START_SIGNAL: 'Tín hiệu bắt đầu',
				END_SIGNAL: 'Tín hiệu kết thúc',
				STATUS: 'Trạng thái',
				ONLINE: 'Hoạt động',
				OFFLINE: 'Không hoạt động',
			}
		},
		SIM: {
			VIETTEL_3G: 'Viettel 3G',
			VINAPHONE_PAY_PAID: 'Vinaphone trả trước',
			MOBIPHONE_PAY_PAID: 'Mobiphone trả trước',
			VIETTEL_PAY_PAID: 'Viettel trả trước',
			VINAPHONE_POST_PAID: 'Vinaphone trả sau',
			MOBIPHONE_POST_PAID: 'Mobiphone trả sau',
			VIETTEL_POST_PAID: 'Viettel trả sau',
			VINAPHONE_3G: 'Vinaphone 3G',
			MOBIPHONE_3G: 'Mobiphone 3G',
			VNET_DATA_M1: 'VNET Data M1',
			VNET_DATA_M2: 'VNET Data M2',
			UNKNOWN: 'Không xác định',
		},
		SENSOR_TYPE: {
			FUEL: 'Nhiên liệu',
			FUEL_SENSOR: 'Thùng xăng dầu phụ',
			TEMPERATURE: 'Nhiệt độ',
			HUMID: 'Độ ẩm',
			PRESSURE: 'Áp lực',
			OTHER: 'Khác',
		},
		SENSOR_TEMPLATE: {
			CALIBRATION: 'Hiệu chuẩn',
			ORIGINAL: 'Nguyên bản',
			LINEAR: 'Tuyến tính',
		},
		MOMENT: {
			IN_DAY: 'trong ngày',
			TO_DAY: 'hôm nay',
		},
		LIBARY: {
			DATE_RANGE_PICKER: {
				TO_DAY: 'Hôm nay',
				ADD_1_WEEK: '1 tuần sau',
				ADD_2_WEEK: '2 tuần sau',
				ADD_1_MONTH: '1 tháng sau',
				ADD_2_MONTH: '2 tháng sau',
				ADD_3_MONTH: '3 tháng sau',
				ADD_6_MONTH: '6 tháng sau',
				ADD_1_YEAR: 'Năm sau',
				ADD_2_YEAR: '2 năm sau',
				YESTERDAY: 'Hôm qua',
				THIS_WEEK: 'Tuần này',
				LAST_WEEK: 'Tuần trước',
				LAST_7_DAYS: '7 ngày gần đây',
				THIS_MONTH: 'Tháng này',
				LAST_MONTH: 'Tháng trước',
				LAST_30_DAY: '30 ngày trước',
				CUSTOM_RANGE_LABLE: 'Tùy chọn',
				APPLY_LABLE: 'Đồng ý',
				CANCEL_LABLE: 'Hủy',
				FROM_LABLE: 'từ',
				TO_LABLE: 'đến',
				DAY_OF_WEEK: {
					SU: 'CN',
					MO: 'T2',
					TU: 'T3',
					WE: 'T4',
					TH: 'T5',
					FR: 'T6',
					SA: 'T7',
				},
				MONTH_NAME: {
					JANUARY: 'Tháng 1',
					FEBRUARY: 'Tháng 2',
					MARCH: 'Tháng 3',
					APRIL: 'Tháng 4',
					MAY: 'Tháng 5',
					JUNE: 'Tháng 6',
					JULY: 'Tháng 7',
					AUGUST: 'Tháng 8',
					SEPTEMBER: 'Tháng 9',
					OCTOBER: 'Tháng 10',
					NOVEMBER: 'Tháng 11',
					DECEMBER: 'Tháng 12',
				},
			},
		},
		COMMON: {
			GENERAL: {
				BASE: 'Cơ bản',
				COMMAND: 'Lệnh gửi',
				SEARCH_ADVANCED: 'Tìm kiếm nâng cao',
				RESET: 'Đặt lại',
				SEARCH: 'Tìm kiếm',
				PARAMETER: 'Thông số',
				EXAMPLE: 'Ví dụ',
				ACTIVE: 'Kích hoạt',
				INACTIVE: 'Không kích hoạt',
				BLOCK: 'Khóa',
				EXPORT_TO: 'Xuất dữ liệu',
				DOWNLOAD: 'Tải xuống',
				DOWNLOAD_ALL: 'Tải tất cả xuống',
				SELECT_ALL: 'Chọn tất cả',
				SIGN_OUT: 'Đăng xuất',
				WELCOME_TO_TOP_GPS: 'Chào mừng bạn đến với TOP GPS',
				CHANGED: 'Đang sạc',
				NO_BATTERY: 'Không có pin',
				ALL: 'Tất cả',
				TOTAL: 'Tổng số',
				DATETIME: 'Ngày giờ',
				POWER_ENGINE: 'Ắc quy',
				BATTERY: 'Pin',
				EXPORT_EXCEL: 'Xuất excel',
				EXPORT_PDF: 'Xuất pdf',
				EXPORT_TXT: 'Xuất txt',
				CLOSE: 'Đóng',
				OPEN: 'Mở',
				UNKNOWN: 'Không xác định',
				CLICK_SHOW_IMAGE: 'Bấm hiển thị ảnh',
				SERVERS: 'Máy chủ',
				INSERT_TEXT: 'Chèn văn bản vào đây',
				NOTIFICATIONS: 'Thông báo',
				PASSWORD: 'Mật khẩu',
				REFESH: 'Làm mới',
				IN: 'Trong vùng',
				ALL_STATE: 'Tất cả trạng thái'
			},
			ACTIONS: {
				ACTIONS: 'Chức năng',
				DELETE: 'Xóa',
				EDIT: 'Sửa',
				ADD: 'Thêm',
				ADD_NEW: 'Tạo mới',
				SAVE_CHANGES: 'Lưu thay đổi',
				CLOSE: 'Đóng',
				CONFIRM: 'Xác nhận',
				CANCEL: 'Hủy bỏ',
				SEND: 'Gửi',
				DETAIL: 'Chi tiết',
				ACTIVE: 'Kích hoạt',
				SOLD: 'Bán',
				LOCK: 'Khóa',
				UNLOCK: 'Mở khóa',
				SAVE: 'Lưu',
				MOVE_POINT: 'Chuyển điểm',
				MOVE: 'Chuyển',
				CANCEL_TRANSACTION: 'Hủy giao dịch',
				REQUEST_CANCEL: 'Yêu cầu huỷ',
				CHOOSE: 'Chọn',
				SERVICE_EXTENSION: 'Gia hạn dịch vụ',
				TRANSFER_MONEY: 'Chuyển tiền',
				LINK: 'Liên kết',
				UNLINK: 'Hủy liên kết'
			},
			COLUMN: {
				ONLY_DEVICE: 'Chọn từng thiết bị',
				NAME: 'Tên',
				DESCRIPTION: 'Mô tả',
				MODIFIELD_BY: 'Chỉnh sửa bởi',
				MODIFIED_BY: 'Chỉnh sửa bởi',
				UPDATED_DATE: 'Ngày cập nhật',
				SORT_ORDER: 'Thứ tự',
				ACTIONS: 'Chức năng',
				FILLCOLOR: 'Màu nền',
				STARTTIME: 'Thời gian bắt đầu',
				ENDTIME: 'Thời gian kết thúc',
				CURRENCYUNIT: 'Đơn vị tiền tệ',
				TYPEGEOFENCE: 'Loại vùng',
				PROVINCEID: 'Tỉnh thành',
				RADIUS: 'Bán kính',
				LATITUDE: 'Vĩ độ',
				LONGITUDE: 'Kinh độ',
				POITYPE: 'Điểm đánh dấu',
				SORTORDER: 'Thứ tự',
				CREATED_BY: 'Người tạo',
				CREATED_DATE: 'Ngày tạo',
				UPDATED_BY: 'Người sửa',
				IMEI: 'IMEI',
				USERNAME: 'Tài khoản',
				TYPE: 'Loại',
				SIM: 'SIM',
				SERVICE_EXPIRE: 'Hạn dịch vụ',
				SERVICE_EXPIRE_OLD: 'Hạn dịch vụ cũ',
				SERVICE_EXPIRE_NEW: 'Hạn dịch vụ mới',
				WARRANTY_EXPIRE: 'Hạn bảo hành',
				STATUS: 'Trạng thái',
				CANCEL: 'Hủy bỏ',
				VALUE: 'Giá trị',
				ADDRESS: 'Địa chỉ',
				DELETE: 'Xóa',
				ADD_DAY: 'Thêm ngày',
				POINT: 'Điểm',
				POINT_OLD: 'Điểm cũ',
				POINT_NEW: 'Điểm mới',
				UNIT: 'Đơn vị',
				CREATE_BY: 'Người tạo',
				ACTION: 'Chức năng',
				NAME_KEY: 'Tên khóa',
				STATUS_CAR: 'Trạng thái xe',
				VIN: 'VIN',
				BATTERY: 'Pin',
				UPDATE_TIME: 'Thời gian cập nhật',
				POWER: 'Nguồn',
				SIGNAL: 'Tín hiệu',
				ACTIVE: 'Kích hoạt',
				IS_SELL: 'Đã bán',
				KEY_LANGUAGE: 'Mã dịch',
				KEY_TRANSLATED: 'Mã chuyển đổi',
				USER_TRANSACTION: 'Tài khoản giao dịch',
				PAY: 'Thanh toán',
				COORDINATE: 'Tọa độ',
				NOTE: 'Ghi chú',
				IMPLEMENTER: 'Người thực hiện',
				PAY_AT: 'Ngày thanh toán',
				BILL_IMG: 'Hóa đơn',
				USER_RENEWAL: 'Tài khoản gia hạn',
				ACCOUNT_DEVICE: 'Tài khoản thiết bị',
				NUMBER_PLATE: 'Biển số',
				DEVICE_NAME: 'Tên thiết bị',
				EMAIL: 'Email',
				PERMISSIONS: 'Quyền',
				LISTPERMISSION: 'Danh sách quyền',
				TIMEFORMAT: 'Thời gian',
				UNITVOLUME: 'Âm lượng',
				UNITWEIGHT: 'Cân nặng',
				SENSORS: 'Cảm biến',
				LANGUAGE: 'Ngôn ngữ',
				ROLENAME: 'Tên vai trò',
				WEEKFIRSTDAY: 'Ngày đầu tuần',
				ROLEID: 'Định danh',
				ICONURL: 'Đường dẫn icon',
				ICONMAPURL: 'Đường dẫn map icon',
				ICONSVG: 'IconSvg',
				WORK: 'Hoạt động',
				ACCOUNT: 'Tài khoản',
				SEVER_NAME: 'Tên máy chủ',
				SERVER: 'Máy chủ',
				DISTRIBUTOR: 'Đại lý',
				SUB_USERNAME: 'TK cấp 3',
				PHONE: 'Điện thoại',
				DEVICE_TYPE: 'Loại TB',
				AMOUNT: 'Số tiền',
				ACCOUNT_TRANSACTION: 'Tài khoản giao dịch',
				DETAIL: 'Chi tiết',
				TOTAL: 'Tổng',
				ADD_POINT: 'Nhận điểm',
				MOVE_POINT: 'Chuyển điểm',
				RENEWS: 'Gia hạn',
				COUNT_TRANSACTION: 'Số giao dịch',
				TOTAL_POINT: 'Tổng điểm',
				AMOUNT_PAID: 'Đã thanh toán',
				TOTAL_AMOUNT: 'Tổng số tiền',
				RENEWS_COUNT: 'Số TB gia hạn',
				DATE: 'Ngày',
				TITLE: 'Tiêu đề',
				USERNAME_MAIN: 'Tài khoản chính',
				COMMAND_NAME: 'Tên lệnh',
				INFO: 'Thông tin',
				FEEDBACK: 'Ý kiến phản hồi',
				FEEDBACK_INFO: 'Thông tin phản hồi',
				CONTENT: 'Nội dung',
				FLATFORM: 'Nền tảng',
				PROCESS_INFO: 'Thông tin xử lý',
				IP_ADDRESS: 'Địa chỉ IP',
				ACCESS_INFO: 'Thông tin truy cập',
				IMAGE: 'Hình ảnh',
				IMAGES: 'Hình ảnh',
				USER_AGENT: 'User-Agent',
				TIME: 'Thời gian',
				LAT_LNG: 'Latitude, Longitude',
				SPEED: 'Tốc độ',
				GSM: 'GSM',
				GPS: 'GPS',
				BASIC: 'Cơ bản',
				TIME_DEVICE: 'Thời gian thiết bị',
				LINK_DOCUMENT: 'Liên kết hướng dẫn sử dụng',
				KEY: 'Mã',
				COMMAND: 'Lệnh gửi',
				NAMEKEY: 'Tên khóa',
				CHANNEL: 'Kênh',
				CHANNEL_LIVESTREAM: 'Kênh để xem camera trực tiếp.',
				INTEGRATE_DEVICE: 'Thiết bị tích hợp',
				OPEN_SOURCE_LICENSES_LINK: 'Liên kết giấy phép mã nguồn mở',
				SUPPORT_MAIL: "Email hỗ trợ",
				SUPPORT_SERVICE_PHONE_NUMBER: "Số điện thoại hỗ trợ dịch vụ",
				SUPPORT_TECH_PHONE_NUMBER: "Số điện thoại hỗ trợ kĩ thuật",
				TEAM_OF_SERVICE_LINK: "Chính sách",
				CHARGER_TYPE: 'Loại sạc',
				ABOUT_US_WEB_LINK: 'Trang giới thiệu',
				PAYMENT_SIM: 'Số sim',
				SERVICE_EXPIRATION: 'Hạn dịch vụ'

			},
			VEHICLE_TYPE:{
				MOTORBIKE: 'Xe máy điện',
				CAR: 'Ô tô điện',
				OTHERS: 'Các phương tiện khác',
				ALL: 'Tất cả'
			},
			VALIDATION: {
				REQUIRED_FIELD: 'vui lòng không để trống',
				EMAIL: 'không đúng định dạng email',
				ELE_EMAIl: '{{email}} không đúng định dạng',
				MIN_LENGTH: 'vui lòng nhập vào tối thiểu là {{min}} ký tự',
				MAX_LENGTH: 'vui lòng nhập tối đa là {{max}} ký tự',
				REQUIRED_FIELD_NAME: 'vui lòng không để trống',
				PLEASE_CHOOSE_TYPE: 'Vui lòng chọn loại',
				PLEASE_ENTER_CORRECT_FORM: 'vui lòng nhập vào đúng kiểu mẫu',
				INVALID: 'giá trị không hợp lệ',
				LEASE_ENTER_NUMBER: 'vui lòng nhập vào số',
				PLEASE_ENTER_MIN_LENGTH:
					'vui lòng nhập vào tối thiểu {{min}} ký tự',
				PLEASE_ENTER_MAX_LENGTH:
					'vui lòng nhập vào tối đa {{max}} ký tự',
				PLEASE_ENTER_RANGE_LENGTH:
					'vui lòng nhập vào từ {{min}} tới {{max}} ký tự',
				PLEASE_ENTER_RANGE:
					'vui lòng nhập vào số từ {{min}} tới {{max}}',
				PLEASE_ENTER_MIN: 'vui lòng nhập vào số lớn hơn {{min}}',
				PLEASE_ENTER_MAX: 'vui lòng nhập vào số nhỏ hơn {{max}}',
				PLEASE_ENTER_PHONE: 'vui lòng nhập vào số điện thoại',
				PLEASE_ENTER_EMAIL: 'vui lòng nhập vào email',
				PLEASE_ENTER_NUMBER: 'vui lòng nhập vào số',
				REQUIRED_DEVICE: 'Danh sách xe không được trống',
				INVALID_EMAIL: " Email '{{email}}' không đúng định dạng ",
			},
			MESSAGE: {
				INTERNAL_SERVER_ERROR: 'Lỗi phía máy chủ',
				NO_RECORDS_FOUND: 'Không tìm thấy dữ liệu',
				NOT_FOUND_DATA: 'Không tìm thấy dữ liệu',
				COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa mục này?',
				COMFIRM_RESET: 'Cài đặt lại mật khẩu?',
				PASSWORD_DEFAULT: "Mật khẩu mặc định là '68686868'",
				COPIED: 'Đã sao chép',
				COPY: 'Sao chép',
				COPY_FALSE: 'Sao chép không thành công',
				NOTHING_SELECTED: 'Không được chọn',
				CONFIRM_CANCEL_TRANSACTION:
					'Bạn có chắc chắn muốn hủy giao dịch?',
				CONFIRM_REQUEST_CANCEL_TRANSACTION:
					'Bạn có chắc chắn muốn yêu cầu hủy giao dịch?',
				SELECT_DEVICE_TO_DISPLAY: 'Chọn thiết bị để hiển thị',
				SELECT_CAMERA_TO_DISPLAY: 'Chọn camera để hiển thị',
				SELECT_FENCE_TO_DISPLAY: 'Chọn vùng để hiển thị.',
				PLEASE_CHOOSE_DEVICE: 'Vui lòng chọn thiết bị',
				DEVICE_NOT_SUPPORT_DEBUG: 'Thiết bị không hỗ trợ ghi dữ liệu',
				PLEASE_ENTER_DEVICE: 'Vui lòng nhập IMEI',
				PLEASE_SELECT_DEVICE_TO_VIEW_AGAIN: "Vui lòng chọn lại camera để xem trực tiếp",
				PLEASE_SEARCH_FOR_PLAYBACK_AGAIN: "Vui lòng tìm kiếm lại",
				PERMISSION_COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa quyền này?',
				IMPORT_SUCCESS: 'Import thành công',
				LINK_ACCOUNT: 'Liên kết ví ưu đãi',
				COMFIRM_LINK_ACCOUNT: 'Bạn có muốn liên kết ví ưu đãi với tài khoản ',
				UNLINK_ACCOUNT: 'Hủy liên kết ví ưu đãi',
				COMFIRM_UNLINK_ACCOUNT: 'Bạn có muốn hủy liên kết ví ưu đãi với tài khoản '
			},
			DATA_TABLE: {
				SELECT_PAGE_SIZE: 'Chọn số bản ghi hiển thị',
				SHOW: 'hiển thị',
				OF: 'của',
				SEARCH: 'Tìm kiếm',
				RESET: 'Đặt lại',
				REFRESH: 'Làm mới',
				PLEASE_WAIT: 'Xin vui lòng chờ ...',
				LAST_PAGE: 'Trang cuối',
				NEXT_PAGE: 'Trang tiếp',
				FIRST_PAGE: 'Trang đầu',
				PREVIOUS_PAGE: 'Trang trước',
				CREATED_DATE: 'Ngày tạo',
				SUCCESS: 'Thành công!',
				FAILED: 'Thất bại!',
			},
			USER_TREE: {
				USER_NAME__ACCOUNT: 'Tên người dùng/Tài khoản',
				POINT: 'Điểm',
				STOCK: 'Kho',
				TOTAL: 'Tổng',
			},
			USER_INFO: {
				USER_INFO: 'Thông tin tài khoản',
				DETAIL: 'Chi tiết',
				GO_TO_USER_MANAGE_PAGE: 'Tới trang quản lý người dùng',
				GO_TO_DEVICE_MANAGE_PAGE: 'Tới trang quản lý thiết bị',
				GO_TO_MAP_PAGE: 'Tới trang bản đồ',
				COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa người dùng này?',
			},
			DEVICE: {
				COMFIRM_DELETE: 'Bạn có chắc bạn muốn xóa trạm sạc này?',
			},
			LIST_DEVICE: {
				GENERAL: {
					OFF: 'Tắt',
					CLOSED: 'Đóng',
					ON: 'Bật',
					OPEN: 'Mở',
				},
        TRKTIME: 'Thời gian',
        CONNECTING: 'Cổng sạc',
        IMAGE:  'Hình ảnh',
				NOT_UPDATE_DATA: 'Chưa cập nhật dữ liệu',
				DEVICE_NOT_SET_COORDINATES:
					'Chưa cập nhật vị trí hoặc tín hiệu gps kém',
				DEVICE_EXPIRED: 'Thiết bị đã hết hạn',
				ALL_DEVICE: 'Tất cả thiết bị',
				ALL: 'Tất cả',
				STOP: 'Dừng',
				RUN: 'Chạy',
				EXPIRED: 'Hết hạn',
				LOST_GPS: 'Mất GPS',
				LOST_SIGNAL: 'Mất tín hiệu',
				FILTER: 'Lọc',
				FILTERS: 'Bộ lọc',
				ALL_GROUPS: 'Tất cả các nhóm',
				ALL_TYPES: 'Tất cả các loại',
				RESET: 'Đặt lại',
				DEVICE_GROUP: 'Nhóm thiết bị',
				DEVICE_TYPE: 'Loại thiết bị',
				DEVICE_INACTIVE: 'Thiết bị chưa kích hoạt dịch vụ',
				IMEI: 'IMEI',
				GROUPS: 'Nhóm',
				ADDRESS: 'Địa chỉ',
				DISTANCE_TODAY: 'Khoảng cách trong ngày',
				UPDATE_TIME: 'Thời gian cập nhật',
				NUMBER_PLATE: 'Biển số',
				VIN_NUMBER: 'Số VIN',
				BATTERY: 'Pin',
				CHANGED: 'Đang sạc',
				DRIVING_TODAY: 'Thời gian lái xe',
				VIN: 'VIN',
				SIMNO: 'Số SIM',
				GEOFENCE: 'Vùng',
				FOLLOW_POPUP: 'Mở cửa sổ theo dõi thiết bị',
				FOLLOW_NEW_WINDOW: 'Mở trang theo dõi thiết bị',
				GSM: {
					LOST_GSM: 'Yếu',
					WEAK: 'Kém',
					MEDIUM: 'Bình thường',
					GOOD: 'Tốt',
					EXCELLENT: 'Rất tốt',
				},
				POWER_VOLTAGE: 'Nguồn',
				DRIVER: 'Lái xe',
				FOLLOW: 'Theo dõi',
				UNFOLLOW: 'Bỏ theo dõi',
				SHOW_ALL_ON_MAP: 'Hiển thị tất cả trên bản đồ',
				HIDE_ALL_ON_MAP: 'Ẩn tất cả trên bản đồ',
				SHOW_ON_MAP: 'Hiển thị trên bản đồ',
				HIDE_ON_MAP: 'Ẩn trên bản đồ',
				HISTORY_TRIPS: 'Xem lại',
				FAVORITE: 'Yêu thích',
				DISFAVOUR: 'Bỏ yêu thích',
				NOTIFICATIONS: 'Thông báo',
				VIEW_FAVORITE_MODE: 'Xem chế độ yêu thích',
				DETAIL: 'Chi tiết',
				SORT_CHECK: 'Sắp xếp thiết bị đã chọn',
				SEND_COMMAND: 'Gửi lệnh',
				LAST_PHOTO: "Ảnh mới nhất",
				DEVICE_NAME: "Tên thiết bị",
				POWER_UP_TIME: 'Thời điểm bật nguồn',
				POWER_UP_DURATION: 'Thời gian bật nguồn'
			},
			ERROR: {
				SOMTHING_WENT_WRONG: 'Đã có lỗi xảy ra!',
				RETURN_BACK: 'Quay trở lại',
				NOT_PERMISSION_TO_VIEW_THIS_PAGE:
					'Bạn không được phép xem trang này!',
			},
			ALERT_POPUP: {
				EVENT_TYPE: 'Loại thông báo',
				TIME: 'Thời gian',
				TIME_IN_OUT: 'Thời gian vào ra',
				DEVICE: 'Thiết bị',
				GEOFENCE: 'Vùng',
				ADDRESS: 'Đại chỉ',
				NOTIFICATION: 'Thông báo',
			},
			MAP_SETTING: {
				MAP_CONFIG: 'Cấu hình bản đồ',
				LIST_DEVICE: 'Danh sách thiết bị',
				INFO_DEVICE: 'Hộp thông tin thiết bị',
				TRACKING: 'Theo dõi',
				HISTORY_TRIPS: 'Xem lại',
				CHART: 'Biểu đồ',
				ICON: 'Biểu tượng',
				DURATION: 'Trạng thái',
				SPEED: 'Tốc độ',
				BATTERY: 'Pin',
				GSM: 'GSM',
				NUMBER_PLATE: 'Biển số',
				IMEI: 'IMEI',
				SIM_NO: 'Số SIM',
				VIN: 'VIN',
				POWER_VOLTAGE: 'Nguồn điện',
				DISTANCE_TODAY: 'Khoảng cách trong ngày',
				DRIVER: 'Lái xe',
				DRIVING_TODAY: 'Lái xe trong ngày',
				UPDATE_TIME: 'Thời gian cập nhật',
				GROUP: 'Nhóm',
				GEOFENCE: 'Vùng',
				ADDRESS: 'Địa chỉ',
				OTHER_DATA: 'Dữ liệu khác',
				BASE: 'Cơ bản',
				ADVANCE: 'Nâng cao',
				DRIVING: 'Lái xe',
				STOP: 'Dừng',
				DISTANCE: 'Khoảng cách',
				SUMMARY: 'Tổng hợp',
				AIR: 'Điều hòa',
				DOOR: 'Cửa',
				ENGINE: 'Động cơ',
				MAP_MULTI_CONFIG: 'Cấu hình theo dõi nhiều xe',
				DISPLAY: 'Hiển thị hộp thông tin',
				SHOW_FULL: 'Hiển thị dạng mở',
				BEN: 'Ben',
				ACC: 'Acc',
				SENSOR: 'Cảm biến'
			},
			USER_PROFILE: {
				POINTS: 'điểm',
				NOTE_POINT: '1 điểm <=> 1 tháng',
				AVAILABLE_BALANCE: 'Số dư khả dụng',
			},
			EXCEL: {
				START_TIME: 'Thời gian bắt đầu',
				END_TIME: 'Thời gian kết thúc',
				TIMEZONE: 'Múi giờ',
				COUNT: 'Số lần',
				TOTAL: 'Tổng',
				AMOUNT: 'Số tiền',
				USER_LOGIN: 'Tài khoản đăng nhập',
				TOTAL_POINT: 'Tổng số điểm',
				TOTAL_COUNT: 'Tổng số lần',
				USER_NAME: 'Tài khoản',
			},
			FEEDBACK: {
				GENERAL: {
					FEEDBACK: 'Gửi phản hồi',
					CONTENT: 'Nội dung',
					CONTENT_NOTE: 'Mô tả vấn đề hoặc chia sẻ ý tưởng',
					SHOTSCREEN: 'Ảnh chụp màn hình',
					IMAGE: 'Hình ảnh',
					CHOOSE_STATUS: 'Chọn trạng thái',
				},
				MESSAGE: {
					BROWSER_NOT_SUPPORT:
						'Trình duyệt không hỗ trợ tính năng này!',
				},
				STATUS: {
					CHOOSE_STATUS: 'Chọn trạng thái',
					PENDDING: 'Chờ xử lý',
					COMPLETE: 'Đã hoàn thành',
					SKIP: 'Bỏ qua',
				},
			},
			UPLOAD_FILE: {
				GENERAL: {
					CROP_IMAGE: 'Cắt ảnh',
					PREVIEW: 'Xem trước',
				},
				VALIDATION: {
					MAX_COUNT: 'Tối đa {{maxCount}} hình ảnh được chọn',
					LENGTH_FILE:
						'Vui lòng chọn hình ảnh có kích thước nhỏ hơn {{maxLength}}MB',
				},
			},
			COMMAND: {
				RESET: 'Khởi động lại',
				RESET_FACTORY: 'Khôi phục mặc định',
				INFO: 'Lấy thông tin',
				TIME_ZONE: 'Thông tin múi giờ',
				SET_TIME_ZONE: 'Cấu hình múi giờ',
				LOCK_CONFIG: 'Khoá cấu hình',
				UNLOCK_CONFIG: 'Mở khoá cấu hình',
				APN: 'Thông tin APN',
				SET_APN: 'Cấu hình APN',
				STATUS: 'Thông tin trạng thái',
				SET_STATUS: 'Cấu hình trạng thái',
				LOCATION: 'Thông tin vị trí',
				HEARBEAT_INTERVAL: 'Cấu hình gói bắt tay',
				TRACKING_INTERVAL: 'Cấu hình gói theo dõi',
				MODE: 'Thông tin chế độ',
				SET_MODE: 'Cấu hình chế độ',
				SHOCK_MODE: 'Thông tin ',
				SLEEP_MODE: 'Get sleep mode',
				SMS_CENTER: 'Thông tin số trung tâm',
				SET_SMS_CENTER: 'Cấu hình số trung tâm',
				SOS: 'SOS',
				IP_PORT: 'Thông tin cổng ip',
				SET_IP_PORT: 'Cấu hình cổng ip',
				HOST: 'Thông tin host',
				SET_HOST: 'Cấu hình host',
				TURN_OFF_ENGINE: 'Tắt máy',
				TURN_ON_ENGINE: 'Bật máy',
				SAVES_BATTERY: 'Chế độ tiếp kiệm pin',
				TURN_OFF: 'Tắt nguồn',
				TURN_ON: 'Bật nguồn',
				CHANGE_PASSWORD: 'Đổi mật khẩu',
				AUTHORIZATION: 'Xác thực',
			},
			SHOW: 'hiển thị',
			OF: 'của',
			SEARCH: 'Tìm kiếm',
			RESET: 'Đặt lại',
			REFRESH: 'Làm mới',
			PLEASE_WAIT: 'Xin vui lòng chờ ...',
			LAST_PAGE: 'Trang cuối',
			NEXT_PAGE: 'Trang tiếp',
			FIRST_PAGE: 'Trang đầu',
			PREVIOUS_PAGE: 'Trang trước',
			CREATED_DATE: 'Ngày tạo',
			SUCCESS: 'Thành công!',
			FAILED: 'Thất bại!',
			EDIT_BY: 'Chỉnh sửa bởi',
			UPLOAD: 'Tải lên',
			SAVE: 'Lưu',
		},
		PERMISSIONS: {
			GENERAL: {
				USER: 'Người dùng',
				DEVICE: 'Thiết bị',
				ROLE: 'Vai trò',
				PERMISSION: 'Quyền',
				MAP: 'Bản đồ',
				REPORT: 'Báo cáo',
				MANAGE: 'Quản lý',
				ADMIN: 'Người quản lý',
				POINT: 'Điểm',
				UTILITIES: 'Tiện ích',
				TEST: 'Kiểm tra',
				COMMAND: 'Lệnh',
				FUEL: 'Nhiên liệu',
				TECHNICAL: 'Kĩ thuật',
				MENU: 'Thanh menu'
			},
			PROFILE: {
				OWN_VIEW: 'Xem hồ sơ cá nhân',
			},
			USER: {
				LIST: 'Danh sách người dùng',
				UPDATE: 'Sửa người dùng',
				CREATE: 'Tạo mới',
				DELETE: 'Xoá',
				TREE: 'Cây thư mục',
				UPDATE_SERVICE_PRICE: 'Sửa giá dịch vụ'
			},
			COMMAND: {
				MANAGE: {
					STATUS: 'Trạng thái lệnh gửi',
				},
				ACTION: {
					LOCK: 'Khóa lệnh gửi',
				},
			},
			DEVICE: {
				ACTION: {
					COMMAND: 'Gửi lệnh',
					IMPORT_DEVICE: 'Nhập thiết bị',
					RENEWED: 'Gia hạn',
					SELL: 'Chuyển/bán thiết bị',
					UPDATE: 'Sửa thiết bị',
					STATUS: 'Thay đổi trạng thái',
					RENEWS: 'Gia hạn',
					SORT_ORDER: 'Sắp xếp',
					DEVICE_GROUP: 'Thêm nhóm thiết bị',
					DRIVER: 'Thêm lái xe',
					CAMERA: 'Thêm camera'
				},
				EDIT: {
					SIM: 'Sửa số SIM',
					GROUP: 'Sửa nhóm',
					ICON: 'Sửa icon',
					ACTIVE_STATUS: 'Sửa trạng thái kích hoạt',
					ACTIVE_CUSTOMER: 'Kích hoạt người dùng',
					DESCRIPTION_ADMIN: 'Sửa ghi chú người quản trị',
					TYPE: 'Sửa loại',
					ADVANCED: 'Sửa nâng cao',
					SOLD: 'Sửa trạng thái bán',
					SERVICE_EXPIRE: 'Sửa hạn dịch vụ',
					EXTENSION: 'Cấu hình mở rộng',
					DEBUG: 'Lưu dữ liệu thô',
					TOLLFEE: 'Trạm thu phí',
					IMEI: 'Sửa imei',
					CORRIDOR: 'Corridor',
					ADVANCED_FEATURE: 'Tính năng nâng cao',
					GROUP_CAMERA: 'Sửa nhóm camera',
					LIVESTREAM: "Livestream"
				},
				// MANAGE: 'Quản lý',
				DELETE: 'Xoá thiết bị',
				RENEWED: {
					ONE_MONTH: 'Cho phép gia hạn 1 tháng',
					USER_OLD_SERVICE: 'Gia hạn tính từ ngày hết hạn cũ',
				},
				DEBUG: 'Lưu dữ liệu thô',
				SHOW: {
					ID: "Hiển thị id thiết bị"
				},
				MANAGE: {
					INFO: 'Quản lý thông tin thiết bị',
					MAP: 'Quản lý thiết bị trên bản đồ',
					CONNECTOR: 'Quản lý cổng sạc'
				}
			},

			PERMISSION: {
				MANAGE: 'Quản lý quyền',
				LIST: 'Danh sách quyền',
			},
			ROLE: {
				MANAGE: 'Quản lý vai trò',
				LIST: 'Danh sách vai trò',
			},
			MAP: {
				MAP: 'Bản đồ',
				TRACKINGS: 'Theo dõi nhiều xe',
				PLAYBACK: 'Xem lại',
				MAP_LITE: 'Bản đồ rút gọn',
				MAP_FULL: 'Bản đồ đầy đủ',
				VIEW: 'Xem bản đồ'
			},
			REPORT: {
				REPORT: 'Báo cáo',
				FUEL: 'Báo cáo nhiên liệu',
				DEVICE: 'Báo cáo thiết bị',
				ROUTE: 'Báo cáo lộ trình',
				ENGINE: 'Báo cáo thời gian nổ máy',
				DATA_LOG: 'Dữ liệu lịch sử',
				SUMMARY_DAY: 'Tổng hợp theo ngày',
				ROUTE_SIMPLE: 'Lộ trình rút gọn',
				GENERAL: 'Tổng hợp',
				GEOFENCE_DETAIL: 'Chi tiết ra vào vùng',
				TOLL_STATION: 'Trạm thu phí',
				ESTIMATED_FUEL: 'Ước tính nhiên liệu',
				TEMPERATURE: 'Nhiệt độ',
				STATUS_HISTORY: 'Dữ liệu lịch sử',
				TRIP: 'Chuyến đi',
				GEOFENCE_SUMMARY: 'Tổng hợp ra vào vùng',
				QCVN_ROUTE: 'Hành trình',
				QCVN_DRIVING_10H: 'Lái xe liên tục 10 tiếng',
				QCVN_STOP: 'Dừng đỗ',
				QCVN_OVER_SPEED: 'Quá tốc độ giới hạn',
				QCVN_SUMMARY_BY_VEHICLE: 'Tổng hợp theo xe',
				QCVN_DATA_TRANSMISSION: 'Truyền dữ liệu',
				QCVN_SPEED: 'Tốc độ',
				QCVN_DRIVING_4H: 'Lái xe liên tục 4 tiếng',
				QCVN_SUMMARY_BY_DRIVER: 'Tổng hợp theo lái xe',
				STOP_POINT: 'Điểm dừng',
				DEBUG: 'Dữ liệu thô',
				DEVICE_SESSION_LOGIN: 'Phiên đăng nhập thiết bị',
				SIM: 'Sim',
				SUPPORTS: 'Hỗ trợ',
				TRANSACTION_HISTORY: 'Lịch sử giao dịch',
				REPAIR_FEE: 'Chi phí sửa chữa',
				CHANGE_BALANCE: 'Số dư thay đổi',
				
			},
			MENU: {
				SYSTEM_CONFIGURATION: 'Cấu hình hệ thống',
				DEVICE_CONFIGURATION: 'Cấu hình thiết bị',
				USER_TREE: 'Quản trị viên',
				DASHBOARD: 'Tổng quan',
				MAP: 'Bản đồ',
				BOOKING: 'Đặt lịch',
				MANAGE: 'Quản lý',
				REPORT: 'Báo cáo',
				TECHNICAL_ASSISTANCE: 'Hỗ trợ kĩ thuật',
				USER: 'Tài khoản',
				CHARGE_STATION: 'Trạm sạc',
				CONNECTOR: 'Cổng sạc',
				RFID_TAG: 'Thẻ RFID',
				WALLET: 'Ví',
				TRANSACTION_HISTORY: 'Lịch sử giao dịch',
				BOOKING_HISTORY: 'Lịch sử đặt lịch',
				CHANGE_BALANCE: 'Số dư thay đổi',
				REPAIR: 'Chi phí sửa chữa',
				ROLE:'Vai trò',
				PERMISSION: 'Quyền',
				LOGIN_PAGE: 'Trang đăng nhập',
				DEVICE_TYPE: 'Loại thiết bị',
				DEVICE_ICON: 'Biểu tượng thiết bị',
				CONNECTOR_TYPE: 'Loại cổng sạc',
				CONNECTOR_ICON: 'Biểu tượng cổng sạc',
				SIM_TYPE: 'Loại sim',
				RAW: 'Lịch sử dữ liệu thô',
				GATEWAY_CONNECTION: 'Các cổng kết nối',
				SERVICE_PRICE: 'Giá dịch vụ',
				DEVICE: 'Thiết bị',
				PAYMENT_SIM: 'Sim thanh toán',
				SERVICE_EXPIRATION: 'Hạn dịch vụ',
				BANK: 'Ngân hàng',
				TERMINAL: 'Đơn vị kinh doanh',
				SYSTEM: 'Hệ thống',
				DEVICE_STATUS: 'Trạng thái thiết bị',
				VOUCHER: 'Ví ưu đãi'
			},
			MANAGE: {
				MANAGE: 'Quản lý',
				USER: 'Quản lý người dùng',
				DEVICE: 'Quản lý thiết bị',
				DEVICE_GROUP: 'Quản lý nhóm thiết bị',
				COMMAND: 'Quản lý lệnh gửi',
				ALIAS: 'Quản lý bí danh người dùng',
				ALERT: 'Quản lý cảnh báo',
				GEOFENCE: 'Quản lý vùng',
				SCHEDULE_REPORT: 'Cấu hình lịch báo cáo',
				ALERT_RULES: 'Quản lý luật cảnh báo',
				ROLE_ALIAS: 'Quản lý bí danh vai trò',
				ACTIVITY_LOG: 'Quản lý nhật ký hoạt động',
				POI: 'Quản lý điểm đánh dấu',
				DRIVER: 'Quản lý lái xe',
				MESSAGE: "Tin nhắn",
				TRIP: 'Chuyến đi',
				API_KEY: "API key",
				GEOFENCE_GROUP: 'Nhóm vùng địa lí',
				REMIND: 'Nhắc nhở'
			},
			ADMIN: {
				ADMIN: 'Admin',
				LOGIN_PAGE: 'Trang đang nhập',
				TRANSPORT_TYPE: 'Loại hình vận tải',
				SIM_TYPE: 'Loại SIM',
				ROLE: 'Vai trò',
				PERMISSION: 'Quyền',
				DEVICE_TYPE: 'Loại thiết bị',
				SENSOR_TEMPLATE: 'Mẫu cảm biến',
				ICON: 'Biểu tượng',
				TOLL_STATION: 'Trạm thu phí',
				SYSTEM_LOG: 'Nhật ký hệ thống',
				FEEDBACK: 'Ý kiến phản hồi',
				UTILITIES: 'Tiện ích',
				NOTICES: 'Thông báo hệ thống',
				POI_TYPE: 'Loại điển đánh dấu',
				TRANSPORT_TYPE_QCVN: 'Loại phương tiện QCVN',
				TOLL_ROAD: 'Tuyến đường thu phí',
				TOLL_SEGMENT: 'Đoạn đường thu phí'
			},
			POINT: {
				ADD: 'Thêm điểm',
				MOVE: 'Chuyển điểm',
				EXTEND: 'Gia hạn',
				TRANSACTION_HISTORY: 'Lịch sử giao dịch',
				RENEWS_HISTORY: 'Lịch sử gia hạn',
				MANAGE: 'Quản lý điểm',
				POINTS: 'Điểm',
				SYNTHESIS_REPORT: 'Báo cáo tổng hợp',
			},
			TRANSACTION: {
				UPDATE: 'Cập nhật giao dịch',
				REQUEST_CANCEL: 'Yêu cầu huỷ',
				CANCEL: 'Huỷ giao dịch',
			},
			UTILITIES: {
				UTILITIES: 'Tiện ích',
				TRACKINGS: 'Theo dõi nhiều xe',
			},
			FUEL: {
				SUMMARY: 'Tổng hợp',
				FUEL: 'Nhiên liệu',
				CHART: 'Biểu đồ'
			},
			SYSTEM: {
				CREATE: 'Tạo mới',
				UPDATE: 'Cập nhật',
				DELETE: 'Xóa',
				DETAIL: 'Thông tin chi tiết',
				SEARCH: 'Danh sách'
			},
			VOUCHER: {
				CREATE: 'Tạo mới',
				UPDATE: 'Cập nhật',
				DELETE: 'Xóa'
			}
		},
		SYSTEM: {
			UPDATED_VERSION: 'Đã cập nhật phiên bản',
		},
		DEVICETYPE: {
			NOT_FOUND: 'Loại thiết bị không tồn tại',
			DELETED_SUCCESS: 'Xóa loại thiết bị thành công',
			DELETED_FAIL: 'Xóa loại thiết bị không thành công',
			NAME_NOT_VALID: 'Tên loại thiết bị không để trống',
			ALREADY_EXIST: 'Loại thiết bị đã tồn tại',
			UPDATED_SUCCESS: 'Cập nhật loại thiết bị thành công',
			UPDATED_FAIL: 'Cập nhật loại thiết bị không thành công',
			CREATED_SUCCESS: 'Tạo mới loại thiết bị thành công',
			CREATED_FAIL: 'Tạo mới loại thiết bị không thành công',
		},
		ICON_TYPE: {
			TAXI: 'Taxi',
			CAR: 'Xe con',
			TRUCK: 'Xe tải',
			CONTAINER: 'Xe container',
			MOTORBIKE: 'Xe máy',
			MOTORCYCLES: 'Mô tô',
			DIRECTION: 'Định hướng',
			OTHER: 'Khác',
		},
		SENSORTPL: {
			GET_LIST_SUCCESS: 'Load danh sách cảm biến thành công!',
			NAME_NOT_VALID: 'Tên cảm biến không hợp lệ',
			ALREADY_EXIST: 'Tên cảm biến đã tồn tại',
			CREATED_SUCCESS: 'Tạo cảm biến thành công',
			CREATED_FAIL: 'Tạo cảm biến không thành công',
			NOT_FOUND: 'Cảm biến template không tồn tại',
			UPDATED_FAIL: 'Sửa cảm biến không thành công',
			UPDATED_SUCCESS: 'Sửa cảm biến thành công',
			DELETED_FAIL: 'Xóa cảm biến không thành công!',
			DELETED_SUCCESS: 'Xóa cảm biến thành công',
		},
		MAP: {
			STATUS: {
        Available: 'Sẵn sàng',
        InUse: 'Đang sạc',
        Full: 'Đầy',
        Repair: 'Sửa chữa',
        NotActive: 'Chưa hoạt động',
        Closed: "Đóng cửa",
        Break: "Hỏng",
		Maintenance: "Bảo trì"
			},
      LABEL: {
        open247: "Mở 24/7",
        notOpen247: "Không mở 24/7",
        bookmark: 'Đánh dấu',
        addPhoto: 'Thêm ảnh',
        direction: 'Chỉ đường',
        edit: 'Chỉnh sửa',
        photo: 'Ảnh',
        nearbyLocation: 'Các trạm gần đây',
        more: 'Xem thêm',
        legend: "Trạng thái trạm sạc",
        filter: "Điều kiện lọc",
        booking: "Đặt lịch",
      },
			FEATURES: {
				SEARCH: 'Tìm kiếm ...',
				CREATE_LANDMARK: 'Tạo mới điểm đánh dấu',
				CREATE_GEOFENCE: 'Tạo mới vùng',
				CENTER: 'Tâm bản đồ',
				EDIT: 'Chỉnh sửa',
				DELETE: 'Xóa',
				LOCATE: 'Định vị trên bản đồ',
				ZOOM_IN: 'Phóng to',
				ZOOM_OUT: 'Thu nhỏ',
				CLUSTER: 'Hiển thị cụm',
				FITBOUND: 'Thu gọn vùng hiển thị',
				STYLE_MARKER_ONE: 'Nhãn',
				STYLE_MARKER_TWO: 'Nhãn + biểu tượng',
				MARKER_STYLE: 'Kiểu hiển thị',
				MAP_TYPE: 'Loại bản đồ',
				ROAD_MAP: 'Bản đồ đường bộ',
				ROAD_MAP_TOPGPS: 'Bản đồ Topgps',
				SATELLITE: 'Bản đồ vệ tinh',
				GOOGLE_ROAD_MAP: 'Bản đồ đường bộ Google',
				GOOGLE_SATELLITE: 'Bản đồ vệ tinh Google',
				LOCATION: 'Vị trí',
				GEOFENCES: 'Vùng',
				LANDMARK: 'Điểm đánh dấu',
				FOLLOW: 'Theo dõi',
				MARKER_POPUP: 'Hiển thị thông tin khi di chuột',
				FULL_SCREEN: 'Mở rộng vùng hiển thị',
				NORMAL_SCREEN: 'Thu nhỏ vùng hiển thị',
				TRAFFIC: 'Giao thông',
				BRIGHTNESS: 'Độ sáng',
				MAP: 'Bản đồ',
				INFO_BOX: 'Hộp thông tin',
				PLAY: 'Phát',
				PAUSE: 'Dừng',
				SPEED: 'Tốc độ',
				COLOR: 'Hiện thị màu theo tốc độ',
				CENTER_POINT: 'Tâm bản đồ',
				DISTANCE_TITLE: 'Đo khoảng cách',
				DISTANCE_GUIDE: 'Bấm vào màn hình để đo khoảng cách',
				DISTANCE_TOTAL: 'Tổng',
				NO_DATA: 'Không có kết quả',
				DIRECTION: 'vn',
				DIRECTION_TITLE: 'Tìm đường',
				DIRECTION_START: 'Chọn điểm khởi hành hoặc bấm trên bản đồ',
				DIRECTION_ADD: 'Thêm địa điểm',
				DIRECTION_END: 'Chọn điểm đến hoặc bấm trên bản đồ',
				DIRECTION_DIRECTION: 'Tìm trên Google Maps',
				DIRECTION_TOLL_FEE_DIRECTION: 'Tìm đường',
				DIRECTION_GOOGLE:
					'Chúng tôi sử dụng Google Maps để giúp tăng trải nghiệm của bạn',
				DIRECTION_HINT: 'Gõ @ để hiện thị danh sách thiết bị',
				DIRECTION_NO_ROUTE: 'Không tìm thấy tuyến đường',
				DIRECTION_GO: 'Đi qua',
				DIRECTION_FEE: 'Ước tính phí',
				DIRECTION_TIME: 'Ước tính thời gian',
				DIRECTION_DISTANCE: 'Ước tính quãng đường',
				DIRECTION_HIGHWAY: 'Đường cao tốc',
				DIRECTION_NATIONAL_ROAD: 'Quốc lộ',
				DIRECTION_PROVINCE_ROAD: 'Tỉnh lộ',
				DIRECTION_UNKNOWN: 'Chưa xác định',
				DIRECTION_LOCATION: 'Lấy vị trí hiện tại',
				DIRECTION_AVOID_FEE: 'Tránh trạm thu phí',
				DIRECTION_CHANGE: 'Kéo để thay đổi tuyến đường',
			},
			CLUSTER: {
				SEARCH: 'Tìm kiếm ...',
			},
			MARKER: {
				PIN: 'Ghim thông tin',
				LOCK_SCREEN: 'Khóa màn hình',
				FOLLOW_DEVICE: 'Theo dõi thiết bị',
			},
			GENERAL: {
				FOLLOW: 'Theo dõi thiết bị',
			},
			TRACKING: {
				GENERAL: {
					ROUTE: 'Lộ trình',
					NOW: 'Hiện tại',
					NOTIFICATIONS: 'Thông báo',
					NO_ITEM: 'Bạn chưa có vùng để theo dõi',
					CREATE_ITEM: 'Tạo mới tại đây',
					TRACKING: 'Đang theo dõi',
					LIST_TRACKING: 'Danh sách',
					PLAYBACK: 'Xem lại',
				},
				ROUTE: {
					SUMMARY: 'Tổng hợp',
					DRIVING: 'Lái xe',
					STOP: 'Dừng',
					DISTANCE: 'Quãng đường',
				},
				INFO: {
					OTHER_DATA: 'Dữ liệu khác',
					AIR: 'Điều hòa',
					DOOR: 'Cửa',
					ENGINE: 'Động cơ',
					BEN: 'Ben',
					ACC: 'Acc',
					SUMMARY: 'Tổng hợp',
					DRIVING: 'Lái xe',
					STOP: 'Dừng',
					DISTANCE: 'Quãng đường',
					TODAY: 'Hôm nay',
					STOPED: 'Dừng',
					SENSORS: 'Cảm biến',
					TURN_ON_OFF: 'Bật tắt nguồn',
					TURN_ON: 'Bật nguồn',
					TURN_OFF: 'Tắt nguồn',
					CONFIRM:
						'Hành động này sẽ bật tắt nguồn thiết bị, bạn chắc chắn chứ?',
					YES: 'Xác nhận',
					NO: 'Hủy bỏ',
				},
				NOTIFICATIONS: {
					MARK_READ_ALL: 'Đánh dấu tất cả là đã đọc',
					REFRESH: 'Làm mới',
					SEE_MORE: 'Xem thêm',
					MARK_AS_READ: 'Đánh dấu là đã đọc',
					MARK_AS_UNREAD: 'Đánh dấu là chưa đọc',
					SHOW_ON_MAP: 'Hiển thị trên bản đồ',
					SHOW_DETAIL: 'Xem chi tiết',
				},
				CHART: {
					SPEED: 'Tốc độ',
					SHOW_CHART: 'Biểu đồ',
					NO_DATA: 'Không có dữ liệu',
					DATA_LOG: 'Dữ liệu lịch sử',
				},
			},
			INFO_BOX: {
				MAP_CONFIG: 'Cấu hình bản đồ',
				LIST_DEVICE: 'Danh sách thiết bị',
				TRACKING: 'Theo dõi',
				HISTORY_TRIPS: 'Xem lại',
				CHART: 'Biểu đồ',
				ICON: 'Biểu tượng',
				DURATION: 'Trạng thái',
				SPEED: 'Tốc độ',
				BATTERY: 'Pin',
				GSM: 'GSM',
				NUMBER_PLATE: 'Biển số',
				IMEI: 'IMEI',
				SIM_NO: 'Số SIM',
				VIN: 'VIN',
				POWER_VOLTAGE: 'Nguồn điện',
				DISTANCE_TODAY: 'Khoảng cách trong ngày',
				DRIVER: 'Lái xe',
				DRIVING_TODAY: 'Lái xe trong ngày',
				UPDATE_TIME: 'Thời gian cập nhật',
				GROUP: 'Nhóm',
				GEOFENCE: 'Vùng',
				ADDRESS: 'Địa chỉ',
				OTHER_DATA: 'Dữ liệu khác',
				BASE: 'Cơ bản',
				ADVANCE: 'Nâng cao',
				DRIVING: 'Lái xe',
				STOP: 'Dừng',
				DISTANCE: 'Khoảng cách',
				SUMMARY: 'Tổng hợp',
				AIR: 'Điều hòa',
				DOOR: 'Cửa',
				ENGINE: 'Động cơ',
				NAME: 'Tên',
				DIRECTION: 'Hướng di chuyển',
				LABEL: 'Nhãn',
				DEVICE_LABEL: 'Tên hiển thị',
			},
		},
		MESSEAGE_CODE: {
			AUTH: {
				NOT_VALID: 'Tài khoản hoặc mật khẩu không đúng',
				NOT_ACTIVE: 'Tài khoản chưa được kích hoạt hoặc đã khóa',
				DELETED: 'Người dùng đã bị xóa',
				SUCCESS: 'Xác thực thành công',
				NOT_FOUND_EMAIL: 'Không tìm thấy email',
				EMAIL_SENT: 'Gửi thành công! Hãy xác nhận mật khẩu qua Email',
				LINK_NOT_FOUND:
					'Rất tiếc! Đây là một liên kết đặt lại mật khẩu không hợp lệ',
				RESET_SUCCESS: 'Đặi lại mật khẩu thành công',
				EMAIL_NOT_VALID: 'Email không tồn tại',
				LINKNOT_FOUND: 'Đường dẫn không tồn tại',
				EXPIRE_DATE: 'Tài khoản đã hết hạn sử dụng',
				INACTIVE: 'Tài khoản chưa được kích hoạt',
			},
			PERMISSION: {
				NAME_NOT_EMPTY: 'Tên không để trống',
				GROUP_NOT_EMPTY: 'Tên nhóm không để trống',
				ALREADY_EXIST: 'Tên quyền đã tồn tại',
				CREATED: 'Tạo quyền thành công',
				NOT_FOUND: 'Tên quyền không tồn tại',
				DESCRIPSION_NOT_EMPTY: 'Mô tả không để trống',
				NAME_ALREADY_EXIST: 'Tên quyền đã tồn tại',
				UPDATED: 'Cập nhật quyền thành công',
				DELETED: 'Xóa quyền thành công',
				DELETED_FAIL: 'Xóa quyền không thành công',
			},
			ROLE: {
				ALREADY_EXIST: 'Vai trò đã tồn tại',
				NOT_CHILD: 'Vai trò hiện tại không phải con tài khoản login',
				NOT_FOUND: 'Vai trò không tồn tại',
				PARENTID_NOT_FOUND: 'Vai trò cha không tồn tại',
				PARENTID_NOT_VALID: 'Vai trò cha không hợp lệ',
				UPDATED_FAIL: 'Cập nhật vai trò không thành công',
				UPDATED: 'Cập nhật vai trò thành công',
				DELETED_FAIL: 'Xóa vai trò không thành công',
				DELETED_PARENTID_FAIL:
					'Không thể xóa vai trò khi đang có vai trò con',
				USED: 'Không thể xóa vai trò khi đang có vai trò con',
				DELETED: 'Xóa vai trò thành công',
				CREATED: 'Tạo vai trò thành công',
			},
			USER: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền cập nhật tài khoản bí danh',
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền xóa tài khoản bí danh',
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền tạo mới tài khoản bí danh',
				NOT_LOAD_CHILD:
					'Chỉ tải người dùng con của người dùng đăng nhập hiện tại',
				NOT_GET: '',
				PARENTID: '',
				DELETED_NOT_CHILD:
					'Không thể xóa người dùng không phải người dùng con',
				RESET_PASSWORD_NOT_CHILD:
					'Không thể cài đặt lại mật khẩu tải khoản khác con',
				PARENTID_NOT_EMPTY: '',
				NOT_VALID: '',
				ROLE_NOT_EMPTY: 'Vai trò không để trống',
				ROLE_NOT_VALID: 'Vai trò không hợp lệ',
				ROLE_NOT_FOUND: 'Không tìm thấy vai trò',
				PARENTID_NOT_FOUND: '',
				NAME_NOT_EMPTY: '',
				NAME_ALREADY_EXIST: 'Tên tài khoản đã tồn tại',
				EMAIL_ALREADY_EXIST: 'Email đã tồn tại',
				CREATED: 'Tạo tài khoản thành công',
				DELETED: 'Xóa tài khoản thành công',
				NOT_FOUND: '',
				DELETED_FAIL_HAVE_CHILD:
					'Không thể xóa tài khoản có tài khoản con',
				DELETED_FAIL_DEVICE: 'Không thể xóa tài khoản chứa thiết bị',
				DELETED_FAIL_DELETED: '',
				DELETED_FAIL_PARENTID: 'Không thể xóa tài khoản cha',
				DELETED_FAIL_NOT_CHILD: '',
				PARENTID_NOT_VALID: 'Tài khoản cha không hợp lệ',
				UPDATED: 'Cập nhật tài khoản thành công',
				UPDATED_SUCCESS: 'Cập thành thông tin tài khoản thành công',
				RESET_FAIL: 'Cài đặt lại mật khẩu lỗi',
				RESET: 'Cài đặt lại mật khẩu thành công',
				NOT_CHILD: '',
				UPDATED_FAIL_PARENTID: 'Không thể cập nhật tài khoản cha',
				UPDATED_FAIL_NOT_CHILD:
					'Không có quyền cập nhật tài khoản khác tài khoản con',
				UPDATED_ROLE_NOT_VALID: 'Không có quyền gán vai trò Admin',
				UPDATED_ROLE_NOT_FOUND: 'Không thể cập nhật người dùng',
				ALREADY_EXIST: 'Tên người dùng đã tồn tại',
				PHONE_ALREADY_EXIST: 'Số điện thoại đã tồn tại',
				PHONE_NOT_VALID: 'Số điện thoại không hợp lệ',
				EMAIL_NOT_VALID: 'Email không hợp lệ',
				UPDATED_PARENTID_NOT_VALID: 'Tài khoản cha không hợp lệ',
				DELETED_SUCCESS: 'Xóa người dùng thành công',
				NOT_CREATED_USER_WITH_PARENT_ID_IS_END_USER:
					'Con của end user không thể tạo người dùng',
				//ALIAS
				ALIAS_CREATED_SUCCESS: 'Tạo bí danh người dùng thành công',
				UPDATED_ALIAS_SUCCESS: 'Cập nhật bí danh người dùng thành công',
				ALIAS_DELETED_SUCCESS: 'Xóa bí danh người dùng thành công',
				FORBIDDEN: 'Chức năng yêu cầu quyền để thực hiện',
				PASSWORD_OLD_NOT_VALID: 'Mật khẩu cũ không đúng',
				CHANGE_PASS_SUCCESS: 'Thay đổi mật khẩu thành công',
			},
			DEVICE: {
				PHONE_ALREADY_EXIST: 'Số SIM đã tồn tại',
				MOVE_SUCCESS: 'Chuyển thiết bị thành công',
				MOVE_FAIL: 'Chuyển thiết bị không thành công',
				EQUIPMENT_NOT_VALID: 'Danh sách thiết bị không hợp lệ',
				USER_NOT_FOUND: 'Không tìm thấy người dùng',
				RECEIVE_NOT_CHILD: 'Tài khoản chuyển đến không hợp lệ',
				NO_DEVICE_SELL: 'Không có thiết bị nào được gia hạn',
				NO_USER_RECEVIE: 'Tài khoản chuyến đến không xác định',
				CAN_NOT_SMALLER_5_MINUTES: 'Không nhỏ hơn 5 phút',
				PHONE_NOT_VALID: 'Số SIM không hợp lệ',

				CHANGE_STATUS_SUCCESS:
					'Thay đổi trạng thái thiết bị thành công',
				CHANGE_STATUS_FAIL:
					'Thay đổi trạng thái thết bị không thành công',

				EXTENSION_SUCCESS: 'Gia hạn thiết bị thành công',
				USER_EXTENSION_NOT_VALID: 'Tài khoản gia hạn không hợp lệ',
				USER_EXTENSION_NOT_CHILD: 'Tài khoản gia hạn không hợp lệ',
				NO_DEVICE_EXTENSION: 'Không có thiết bị nào được gia hạn',
				USER_NOT_ENOUGH_POINT: 'Tài khoản không đủ điểm để gia hạn',
				DATE_FORMAT_NOT_VALID: 'Thời gian gia hạn không hợp lệ',
				EXTENSION_FAIL: 'Gia hạn thiết bị không thành công',

				ALREADY_EXIST: 'Thiết bị đã tồn tại',
				GROUP_NOT_FOUND: 'Không tìm thấy nhóm thết bị',
				TYPE_NOT_FOUND: 'Không tìm thấy loại thiết bị',
				IMEI_NOT_VALID: 'IMEI không hợp lệ',
				CREATED: 'Thêm thiết bị thành công',
				USER_NOT_CHILD: 'Tài khoản không hợp lệ',
				GROUP_NOT_VALID: 'Nhóm thiết bị không hợp lệ',
				SIM_NOT_FOUND: 'Loại SIM không tồn tại',
				SIM_NOT_VALID: 'Loại SIM không hợp lệ',
				ICON_NOT_FOUND: 'Không tìm thấy loại biểu tượng',
				ICON_NOT_VALID: 'Loại biểu tượng không hợp lệ',
				NO_DEVICE_IMPORT: 'Không có thiết bị nào được thêm',

				DELETED_SUCCESS: 'Xóa thiết bị thành công',
				FORBIDDEN: 'Chức năng yêu cầu quyền để thực hiện',
				DELETED_FAIL: 'Xóa thiết bị không thành công',

				SORT_ORDER_SUCCESS: 'Sắp xếp thiết bị thành công',
				SORT_ORDER_FAIL: 'Sắp xếp thiết bị không thành công',
				IMEI_ALREADY_EXIST: 'Imei đã tồn tại',
				UPDATED_SUCCESS: 'Cập nhật thiết bị thành công',
				SELL_SUCCESS: 'Bán thiết bị thành công',
				SELL_FAIL: 'Bán thiết bị không thành công',
				NO_SELL: 'Không có thiết bị nào được chọn',
				SOLD: 'Thiết bị đã được bán',
				IMEI_NOT_EMPTY: 'Vui lòng nhập IMEI	',
				NUMBER_PLATE_ALREADY_EXIST: "Biển số xe đã tồn tại trên hệ thống",
				NUMBER_PLATE_NOT_VALID: "Biển số xe không hợp lệ"
			},
			SIM: {
				ALREADY_EXIST: 'Tên SIM đã tồn tại',
				NOT_VALID: 'Tên SIM không hợp lệ',
				CREATED_SUCCESS: 'Tạo mới loại SIM thành công',
				NOT_FOUND: 'Không tìm thấy loại SIM',
				UPDATED_SUCCESS: 'Cập nhật loại SIM thành công',
				DELETED_SUCCESS: 'Xóa loại SIM thành công',
				DELETED_FAIL: 'Xóa loại SIM không thành công',
			},
			DEVICETYPE: {
				NOT_FOUND: 'Loại thiết bị không tồn tại',
				DELETED_SUCCESS: 'Xóa loại thiết bị thành công',
				DELETED_FAIL: 'Xóa loại thiết bị không thành công',
				NAME_NOT_VALID: 'Tên loại thiết bị không để trống',
				ALREADY_EXIST: 'Loại thiết bị đã tồn tại',
				UPDATED_SUCCESS: 'Cập nhật loại thiết bị thành công',
				UPDATED_FAIL: 'Cập nhật loại thiết bị không thành công',
				CREATED_SUCCESS: 'Tạo mới loại thiết bị thành công',
				CREATED_FAIL: 'Tạo mới loại thiết bị không thành công',
				PROTOCOL_NOT_EMPTY: 'Tên giao thức không để trống',
			},
			DEVICE_GROUP: {
				SEACH_NOT_VALID: 'Dữ liệu tìm kiếm không hợp lệ',
				GET_LIST_FAIL: 'Lấy danh sách nhóm thiết bị thất bại',
				CREATED_FAIL: 'Tạo nhóm thiết bị không thành công',
				CREATED_SUCCESS: 'Tạo nhóm thiết bị thành công',
				ALREADY_EXIST: 'Tên nhóm thiết bị đã tồn tại',
				NAME_NOT_EMPTY: 'Tên nhóm thiết bị không để trống',
				NOT_FOUND: 'Nhóm thiết bị không tồn tại',
				UPDATED_FAIL: 'Cập nhật nhóm thiết bị không thành công',
				UPDATED_SUCCESS: 'Cập nhật nhóm thiết bị thành công',
				DELETED_FAIL: 'Xóa nhóm thiết bị không thành công',
				DELETED_SUCCESS: 'Xóa nhóm thiết bị thành công',
			},
			SENSOR: {
				DELETED_SUCCESS: 'Xóa cảm biến thành công',
				NOT_FOUND: 'Không tìm thấy cảm biến',
				DELETED_FAIL: 'Xóa cảm biến không thành công',
				UPDATED_SUCCESS: 'Cập nhật cảm biến thành công',
				NAME_NOT_EMPTY: 'Tên cảm biến không để trống',
				DEVICE_NOT_EMPTY: 'Thiết bị không xác định',
				TYPE_SENSOR_NOT_EMPTY: 'Loại cảm biến không xác định',
				DEVICE_NOT_FOUND: 'Không tìm thấy thiết bị',
				PARAMETER_NOT_VALID: 'Tham số không xác định',
				UPDATED_FAIL: 'Cập nhật sensor không thành công',
				CREATED_SUCCESS: 'Tạo cảm biến thành công',
				CREATED_FAIL: 'Tạo cảm biến không thành công',
				FORMULA_ERROR: 'Công thức không hợp lệ',
				PARAMETER_NOT_FOUND: 'Tham số không hợp lệ',
				CALIBRATION_FAIL: 'Hiệu chuẩn không hợp lệ',
				PARAMETER_NAME_NOT_EMPTY: 'Tham số không để trống',
				SORT_ORDER_SUCCESS: 'Sắp xếp cảm biến thành công',
			},
			DEVICE_ICON: {
				GET_LIST_SUCCES: 'Lấy danh sách biểu tượng thành công',
				GET_LIST_FAIL: 'Lấy danh sách biểu tượng thất bại',
				NAME_NOT_EMPTY: 'Tên biểu tượng không để trống',
				NAME_KEY_NOT_EMPTY: 'Khóa biểu tượng không để trống',
				ALREADY_EXIST: 'Tên khóa thiết bị đã tồn tại.',
				CREATED_FAIL: 'Tạo biểu tượng thiết bị thất bại',
				CREATED_SUCCESS: 'Tạo biểu tượng thiết bị thành công',
				UPDATED_FAIL: 'Sửa loại biểu tượng thiết bị không thành công',
				UPDATED_SUCCESS: 'Sửa loại biểu tượng thiết bị thành công ',
				NOT_FOUND: 'Biểu tượng thiết bị không tồn tại ',
				DELETED_FAIL: 'Xóa biểu tượng thiết bị không thành công  ',
				DELETED_SUCCESS: 'Xóa biểu tượng thiết bị thành công ',
			},
			DRIVER: {
				SEACH_NOT_VALID: 'Dữ liệu tìm kiếm không hợp lệ',
				GET_LIST_FAIL: 'Lây danh sách lái xe không thành công',
				CREATED_SUCCESS: 'Tạo mới lái xe thành công',
				PHONE_NOT_VALID: 'Số điện thoại không hợp lệ',
				EMAIL_NOT_VALID: 'Email không hợp lệ',
				PHONE_ALREADY_EXIST: 'Số điện thoại đã tồn tại',
				EMAIL_ALREADY_EXIST: 'Email đã tồn tại',
				NAME_NOT_EMPTY: 'Tên lái xe không để trống',
				CREATED_FAIL: 'Tạo lái xe không thành công',
				USER_NOT_VALID: 'Tài khoản chỉ định không hợp lệ',
				USER_NOT_CHILD: 'Tài khoản chỉ định không hợp lệ',
				LICENSEN_NUMBER_ALREADY_EXIST: 'Giấy lái xe đã tồn tại',
				DRIVER_CODE_ALREADY_EXIST: 'Mã lái xe đã tồn tại',
				UPDATED_SUCCESS: 'Cập nhật lái xe thành công',
				NOT_FOUND: 'Lái xe không tồn tại',
				BEGIN_DATE_NOT_VALID: 'Ngày cấp không hợp lệ',
				EXPIRE_DATE_NOT_VALID: 'Ngày hết hạn không hợp lệ',
				UPDATED_FAIL: 'Cập nhật lái xe không thành công',
				DELETED_FAIL: 'Xóa lái xe không thành công',
				DELETED_SUCCESS: 'Xóa lái xe thành công',
			},
			SENSORTPL: {
				GET_LIST_SUCCESS: 'Load danh sách cảm biến thành công!',
				NAME_NOT_VALID: 'Tên cảm biến không hợp lệ',
				ALREADY_EXIST: 'Tên cảm biến đã tồn tại',
				CREATED_SUCCESS: 'Tạo cảm biến thành công',
				CREATED_FAIL: 'Tạo cảm biến không thành công',
				NOT_FOUND: 'Cảm biến template không tồn tại',
				UPDATED_FAIL: 'Sửa cảm biến không thành công',
				UPDATED_SUCCESS: 'Sửa cảm biến thành công',
				DELETED_FAIL: 'Xóa cảm biến không thành công!',
				DELETED_SUCCESS: 'Xóa cảm biến thành công',
			},
			DEVICEICON: {
				GET_LIST_SUCCES: 'Lấy danh sách biểu tượng thành công',
				GET_LIST_FAIL: 'Lấy danh sách biểu tượng thất bại',
				NAME_NOT_EMPTY: 'Tên biểu tượng không để trống',
				NAME_KEY_NOT_EMPTY: 'Khóa biểu tượng không để trống',
				DELETED_SUCCESS: 'Xóa lái xe thành công',
				ALREADY_EXIST: 'Tên khóa thiết bị đã tồn tại.',
				CREATED_FAIL: 'Tạo biểu tượng thiết bị thất bại',
				CREATED_SUCCESS: 'Tạo biểu tượng thiết bị thành công',
				UPDATED_FAIL: 'Sửa loại biểu tượng thiết bị không thành công',
				UPDATED_SUCCESS: 'Sửa loại biểu tượng thiết bị thành công ',
				NOT_FOUND: 'Biểu tượng thiết bị không tồn tại ',
				DELETED_FAIL: 'Xóa biểu tượng thiết bị không thành công  ',
			},
			TRANSPORT_TYPE: {
				CREATED_SUCCESS: 'Tạo loại hình vận tải thành công',
				CREATED_FAIL: 'Tạo loại hình vận tải thất bại',
				NAME_KEY_NOT_EMPTY: 'Từ khóa không được để trống',
				LIMIT_SPEED_NOT_VALID: 'Tốc độ giới hạn không hợp lệ',
				QCVNCODE_NOT_VALID: 'QCVN không hợp lệ',
				UPDATED_SUCCESS: 'Cập hình loại hình vận tải thành công',
				UPDATED_FAIL: 'Cập nhật loại hình vận tải thất bại',
				DELETED_SUCCESS: 'Xóa loại hình vận tải thành công',
				DELETED_FAIL: 'Xóa loại hình vận tải thất bại',
				NAME_KEY_ALREADY_EXIST: 'Từ khóa đã tồn tại',
				SORT_ORDER_SUCCESS: 'Sắp xếp thành công',
			},
			TRANSPORT_TYPE_QCVN: {
				CREATED_SUCCESS: 'Tạo loại hình vận QCVN tải thành công',
				CREATED_FAIL: 'Tạo loại hình vận tải QCVN thất bại',
				NAME_KEY_NOT_EMPTY: 'Từ khóa không được để trống',
				LIMIT_SPEED_NOT_VALID: 'Tốc độ giới hạn không hợp lệ',
				QCVNCODE_NOT_VALID: 'QCVN không hợp lệ',
				UPDATED_SUCCESS: 'Cập hình loại hình vận tải QCVN thành công',
				UPDATED_FAIL: 'Cập nhật loại hình vận tải QCVNthất bại',
				DELETED_SUCCESS: 'Xóa loại hình vận tải QCVN thành công',
				DELETED_FAIL: 'Xóa loại hình vận tải QCVN thất bại',
				NAME_KEY_ALREADY_EXIST: 'Từ khóa đã tồn tại',
			},
			DEVICEGROUP: {
				CREATED_FAIL: 'Tạo nhóm thiết bị thất bại',
				CREATED_SUCCESS: 'Tạo nhóm thiết bị thành công',
				ALREADY_EXIST: 'Nhóm thiết bị đã tồn tại',
				NAME_NOT_EMPTY: 'Không thể bỏ trống tên của nhóm thiết bị',
				NOT_FOUND: 'Nhóm thiết bị không tồn tại',
				UPDATED_FAIL: 'Sửa nhóm thiết bị thất bại',
				UPDATED_SUCCESS: 'Sửa nhóm thiết bị thành công',
				DELETED_FAIL: 'Xóa nhóm thiết bị thất bại',
				DELETED_SUCCESS: 'Xóa nhóm thiết bị thành công',
			},
			COMMAND: {
				UPDATED_SUCCESS: 'Cập nhật trạng thái thành công',
				CREATED_SUCCESS: 'Tạo lệnh gửi thành công',
			},

			ALERT_RULE: {
				CREATED_SUCCESS: 'Tạo cảnh báo thành công',
				NAME_NOT_EMPTY: 'Tên cảnh báo không đúng',
				EVENT_TYPE_NOT_EMPTY: 'Loại cảnh báo không đúng',
				DEVICE_ID_NOT_EMPTY: 'Danh sách thiết bị trống',
				GEOFENCE_IDS_NOT_VALID: 'Danh sách vùng địa lý không hợp lệ',
				SCHEDULE_NOT_VALID: 'Lập lịch không đúng',
				EVENT_TYPE_NOT_FOUND: 'Không tìm thấy loại cảnh báo',
				DELETED_SUCCESS: 'Xóa cảnh báo thành công',
				DELETED_FAIL: 'Xóa cảnh báo thất bại',
				UPDATED_SUCCESS: 'Cập nhật cảnh báo thành công',
				UPDATED_FAIL: 'Cập nhật cảnh báo thất bại',
				NOT_FOUND: 'Cảnh báo ko tồn tại',
				GET_LIST_DEVICE_FAIL: 'Lấy danh sách thiết bị không thành công',
				DEVICE_NOT_VALID: 'Thiết bị không hợp lệ',
				PUSH_SETTING_NOT_EMPTY: 'Cấu hình thông báo không hợp lệ',
				PUSH_SETTING_NOT_VALID: 'Cấu hình thông báo không hợp lệ',
				EXTENDED_PARAM_NOT_VALID:
					'Giá trị theo loại cảnh cáo không hợp lệ',
			},

			GEOFENCES: {
				NOT_FOUND: 'Không tìm thấy vùng',
				DELETED_FAIL: 'Xóa vùng không thành công',
				DELETED_SUCCESS: 'Xóa vùng thành công',
				UPDATED_FAIL: 'Sửa vùng không thành công',
				TYPE_NOT_FOUND: 'Loại vùng không xác định',
				NOT_CHILD: 'Tài khoản chỉ định không hợp lệ',
				UPDATED_SUCCESS: 'Cập nhật vùng thành công',
				CREATED_SUCCESS: 'Tạo vùng thành công',
				USER_NOT_VALID: 'Tài khoản chỉ định không hợp lệ',
				NAME_NOT_EMPTY: 'Tên không để trống',
				CREATED_FAIL: 'Tạo vùng không thành công',
				CHANGE_STATUS_SUCCESS: 'Đổi trạng thái vùng thành công',
			},
			LOGIN_PAGE: {
				UPDATED_SUCCESS: 'Cập nhật trang đăng nhập thành công',
				CREATED_SUCCESS: 'Tạo trang đăng nhập thành công',
			},
			SCHEDULED_REPORT: {
				CREATED_SUCCESS: 'Tạo lập lịch báo cáo thành công',
				CREATED_FAIL: 'Tạo lập lịch báo cáo thất bại',
				DELETED_SUCCESS: 'Xóa lập lịch báo cáo thành công',
				DELETED_FAIL: 'Xóa lập lịch báo cáo thất bại',
				UPDATED_SUCCESS: 'Cập nhật lập lịch báo cáo thành công',
				UPDATED_FAIL: 'Cập nhật lập lịch báo cáo thất bại',
				TYPE_REPORT_ALREADY_EXIST: 'Loại báo cáo đã tồn tại',
				DEVICE_NOT_VALID: 'Danh sách xe không đúng',
			},
			DEVICE_FAVORITE: {
				DELETED_FAIL: 'Bỏ yêu thích không thành công',
				DELETED_SUCCESS: 'Đã bỏ yêu thích',
				NOT_CHILD: 'Tài khoản chỉ định không hợp lệ',
				CREATED_SUCCESS: 'Đã thêm yêu thích',
				USER_NOT_VALID: 'Tài khoản chỉ định không hợp lệ',
				CREATED_FAIL: 'Thêm yêu thích không thành công',
			},
			ROLE_ALIAS: {
				UPDATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền cập nhật tài khoản bí danh',
				DELETED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền xóa tài khoản bí danh',
				CREATED_USER_ALIAS_WHEN_YOU_ARE_A_ALIAS:
					'Tài khoản bí danh không có quyền tạo mới tài khoản bí danh',
				CREATED_SUCCESS: 'Tạo vai trò bí danh thành công',
				CREATED_FAIL: 'Tạo vai trò bí danh thất bại',
				DELETED_SUCCESS: 'Xóa vai trò bí danh thành công',
				DELETED_FAIL: 'Xóa vai trò bí danh thất bại',
				UPDATED_SUCCESS: 'Cập nhật vai trò bí danh thành công',
				UPDATED_FAIL: 'Cập nhật vai trò bí danh thất bại',
				NOT_UPDATED_ROLE_WHEN_YOU_ARE_A_ALIAS:
					'Bạn không thể cập nhật vai trò khi bạn là bí danh người dùng',
				NOT_UPDATED_ROLE_FOR_USED_NOT_CHILD:
					'Bạn không thể cập nhật vai trò khi bạn là bí danh người dùng',
				PERMISSION_DO_NOT_ALLOW_EXTEND: 'Quyền đã chọn không hợp lệ',
				USED: 'Vai trò đang được sử dụng',
			},
			POINT: {
				USER_EXTENSION_NOT_CHILD: 'Tài khoản gia hạn không hợp lệ',
				NO_DEVICE_EXTENSION: 'Không có thiết bị nào được gia hạn',
				EQUIPMENT_NOT_VALID: 'Thiết bị không hợp lệ',
				USER_NOT_FOUND: 'Tài khoản gia hạn không hợp lệ',
				EXTENSION_FAIL: 'Gian hạn không thành công',
				MONTH_NOT_VALID: 'Tháng gian hạn không hợp lệ',
				USER_NOT_ENOUGH_POINT: 'Tài khoản không đủ điểm để gian hạn',
				EXTENSION_SUCCESS: 'Gia hạn thành công',
				PAYAT_NOT_EMPTY: 'Ngày thanh toán không được để trống',
				ADD_POINT_SUCCESS: 'Thêm điểm thành công',
				FORBIDDEN: 'Chức năng yêu cầu quyền',
				POINT_NOT_EMPTY: 'Điểm không để trống',
				POINT_NOT_VALID: 'Điểm không hợp lệ',
				ADD_POINT_FAIL: 'Thêm điểm không thành công',
				SELL_POINT_SUCCESS: 'Chuyển điểm thành công',
				NOT_CHILD: 'Tài khoản không hợp lệ',
				POINT_NOT_ENOUGH_POINT: 'Không đủ điểm',
				SELL_POINT_FAIL: 'Chuyển điểm không thành công',
				MONTH_DEVICE_EXTENSION_NOT_VALID: 'Tháng gia hạn không hợp lệ',
				USER_REVICE_NOT_CHILD: 'Tài khoản gia hạn không hợp lệ',
				USER_REVICE_NOT_CHILD_EXTENSION:
					'Tài khoản gia hạn không hợp lệ',
			},
			TRANSACTION: {
				FORBIDDEN: 'Chức năng yêu cầu quyền',
				CANCEL_FAIL: 'Hủy giao dịch không thành công',
				CANCEL_SUCCESS: 'Hủy giao dịch thành công',
				CAN_NOT_CANCEL: 'Loại hoặc trạng thái giao dịch không hợp lệ',
				UPDATED_SUCCESS: 'Cập nhật giao dịch thành công',
				PAYAT_NOT_VALID: 'Ngày thanh toán không hợp lệ',
				UPLOAD_BILL_IMAGE_FAIL: 'Hóa đơn không hợp lệ',
				UPDATED_FAIL: 'Cập nhật giao dịch không thành công',
				CAN_NOT_UPDATED_CANCEL: 'Cập nhật giao dịch không thành công',
				REQUEST_CANCEL_SUCCESS: 'Yêu cầu huỷ giao dịch thành công',
				CAN_NOT_REQUEST_CANCEL:
					'Yêu cầu huỷ giao dịch không thanh công',
			},
			CARD: {
				CREATED_SUCCESS: 'Tạo mã thẻ thành công',
				CREATED_FAIL: 'Tạo mã thẻ thất bại',
			},
			SYSTEM_LOG: {
				CREATED_SUCCESS: 'Tạo phiên bản thành công',
				CREATED_FAIL: 'Tạo phiên bản thất bại',
				UPDATED_SUCCESS: 'Cập nhật phiên bản thành công',
				UPDATED_FAIL: 'Cập nhật phiên bản thất bại',
			},
			MESSAGE: {
				SEND_SUCCESS: 'Gửi tin nhắn thành công',
				SEND_FAIL: 'Gửi tin nhắn thất bại',
				DELETED_SUCCESS: 'Xóa tin nhắn thành công',
				DELETED_FAIL: 'Xóa tin nhắn thất bị',
				MARK_READ_ALL_SUCCESS: 'Đánh dấu tất cả tin nhắn thành công',
			},
			NOTICE: {
				CREATED_SUCCESS: 'Tạo thông báo thành công',
				CREATED_FAIL: 'Tạo thông báo thất bại',
				DELETED_SUCCESS: 'Xóa thông báo thành công',
				DELETED_FAIL: 'Xóa thông báo thất bị',
				UPDATED_SUCCESS: 'Cập nhật thông báo thành công',
				UPDATED_FAIL: 'Cập nhật thông báo thất bại',
			},
			FUEL_CHANGE: {
				DEVICE_ID_NOT_EMPTY: 'Thiết bị không được để trống',
			},
			POI_TYPE: {
				CREATED_SUCCESS: 'Tạo loại POI thành công',
				CREATED_FAIL: 'Tạo loại POI thất bại',
				DELETED_SUCCESS: 'Xóa loại POI thành công',
				DELETED_FAIL: 'Xóa loại POI thất bị',
				UPDATED_SUCCESS: 'Cập nhật loại POI thành công',
				UPDATED_FAIL: 'Cập nhật loại POI thất bại',
				ALREADY_EXIST: 'Loại POI đã tồn tại',
			},
			POI: {
				CREATED_SUCCESS: 'Tạo điểm đánh dấu thành công',
				CREATED_FAIL: 'Tạo điểm đánh dấu thất bại',
				DELETED_SUCCESS: 'Xóa điểm đánh dấu thành công',
				DELETED_FAIL: 'Xóa điểm đánh dấu thất bị',
				UPDATED_SUCCESS: 'Cập nhật điểm đánh dấu thành công',
				UPDATED_FAIL: 'Cập nhật điểm đánh dấu thất bại',
				NAME_ALREADY_EXIST: 'điểm đánh dấu đã tồn tại',
				POI_TYPE_NOT_EMPTY: 'Xin vui lòng chọn loại điểm đánh dấu',
			},
			FEEDBACK: {
				GET_LIST_FAIL: 'Lỗi danh sách ý kiến phản hồi',
				GET_LIST_SUCCESS: '',
				SEND_SUCCESS: 'Cảm ơn bạn đã phản hồi',
				PHONE_NOT_VALID: 'Số điện thoại không hợp lệ',
				EMAIL_NOT_VALID: 'Email không hợp lệ',
				NOT_FOUND: 'Cập nhật không thành công',
				UPDATED_SUCCESS: 'Cập nhật thành công',
			},
			GEOFENCE_GROUPS: {
				CREATED_SUCCESS: 'Tạo nhóm vùng thành công',
				CREATED_FAIL: 'Tạo nhóm vùng thất bại',
				DELETED_SUCCESS: 'Xóa nhóm vùng thành công',
				DELETED_FAIL: 'Xóa nhóm vùng thất bị',
				UPDATED_SUCCESS: 'Cập nhật nhóm vùng thành công',
				UPDATED_FAIL: 'Cập nhật nhóm vùng thất bại',
				ALREADY_EXIST: 'Nhóm vùng đã tồn tại',
			},
			TOLL_STATION: {
				CREATED_SUCCESS: 'Tạo trạm thu phí thành công',
				CREATED_FAIL: 'Tạo trạm thu phí thất bại',
				DELETED_SUCCESS: 'Xóa trạm thu phí thành công',
				DELETED_FAIL: 'Xóa trạm thu phí thất bị',
				UPDATED_SUCCESS: 'Cập nhật trạm thu phí thành công',
				UPDATED_FAIL: 'Cập nhật trạm thu phí thất bại',
				ALREADY_EXIST: 'Trạm thu phí đã tồn tại',
				TOLL_ROAD_NOT_VALID: 'Xin vui lòng chọn đường',
				TYPE_TOLL_STATION_TYPE_TOLL_ROAD_IS_HIGHWAY:
					'Loại trạm và đường khác nhau',
			},
			CAMERA: {
				CREATED_SUCCESS: 'Tạo camera thành công',
				CREATED_FAIL: 'Tạo camera thất bại',
				DELETED_SUCCESS: 'Xóa camera thành công',
				DELETED_FAIL: 'Xóa camera thất bị',
				UPDATED_SUCCESS: 'Cập nhật camera thành công',
				UPDATED_FAIL: 'Cập nhật camera thất bại',
				ALREADY_EXIST: 'Trạm thu phí đã tồn tại',
				CAMERA_ID_NOT_VALID: 'Camera id không đúng định dạng',
				NAME_ALREADY_EXIST: 'Tên Camera đã tồn tại',
				CAMERA_ID_ALREADY_EXIST: 'Camera id đã tồn tại',
				CAMERA_NAME: 'Tên camera',
			},
			USER_MAP_KEY: {
				CREATED_SUCCESS: 'Tạo từ khóa thành công',
				CREATED_FAIL: 'Tạo từ khóa thất bại',
				DELETED_SUCCESS: 'Xóa từ khóa thành công',
				DELETED_FAIL: 'Xóa từ khóa thất bị',
				UPDATED_SUCCESS: 'Cập nhật từ khóa thành công',
				UPDATED_FAIL: 'Cập nhật từ khóa thất bại',
				KEY_ALREADY_EXIST: 'Từ khóa đã tồn tại',
				TYPE_NOT_EMPTY: 'Loại từ khóa không được để trống',
				KEY_NOT_VALID: 'API key không hợp lệ',
				CHANGE_STATUS_SUCCESS: 'Thay đổi trạng thái key thành công',
				USER_NOT_EMPTY: 'Người dùng đã có trong danh sách',
				KEY_NOT_EMPTY: 'API key không được để trống',
			},
			TOLL_ROAD: {
				CREATED_SUCCESS: 'Tạo đường thu phí thành công',
				CREATED_FAIL: 'Tạo đường thu phí thất bại',
				DELETED_SUCCESS: 'Xóa đường thu phí thành công',
				DELETED_FAIL: 'Xóa đường thu phí thất bị',
				UPDATED_SUCCESS: 'Cập nhật đường thu phí thành công',
				UPDATED_FAIL: 'Cập nhật đường thu phí thất bại',
				ALREADY_EXIST: 'Đường thu phí đã tồn tại',
				CAN_NOT_UPDATED_TYPE_TOLL_ROAD_ALREADY_EXIST_TOLL_SEGMENT:
					'Đường đã được gán cho tuyến thu phí',
			},
			TOLL_SEGMENT: {
				CREATED_SUCCESS: 'Tạo tuyến thu phí thành công',
				CREATED_FAIL: 'Tạo tuyến thu phí thất bại',
				DELETED_SUCCESS: 'Xóa tuyến thu phí thành công',
				DELETED_FAIL: 'Xóa tuyến thu phí thất bị',
				UPDATED_SUCCESS: 'Cập nhật tuyến thu phí thành công',
				UPDATED_FAIL: 'Cập nhật tuyến thu phí thất bại',
				ALREADY_EXIST: 'Tuyến thu phí đã tồn tại',
				TOLL_ROAD_STATION_EXIT_STATION_ALREADY_EXIST:
					'Trạm vào và trạm ra đã tồn tại',
				ENTRY_STATION_EXIT_STATION_ALREADY_EXIST:
					'Trạm vào ra đã tồn tại',
			},

			TRIP: {
				CREATED_SUCCESS: 'Tạo chuyến thành công',
				CREATED_FAIL: 'Tạo chuyến thất bại',
				DELETED_SUCCESS: 'Xóa chuyến thành công',
				DELETED_FAIL: 'Xóa chuyến thất bị',
				UPDATED_SUCCESS: 'Cập nhật chuyến thành công',
				UPDATED_FAIL: 'Cập nhật chuyến thất bại',
				ALREADY_EXIST: 'Danh sách điểm đã tồn tại',
				NAME_ALREADY_EXIST: 'Tên chuyến đã tồn tại',
			},
			REMIND: {
				CREATED_SUCCESS: 'Tạo nhắc nhở thành công',
				CREATED_FAIL: 'Tạo nhắc nhở thất bại',
				DELETED_SUCCESS: 'Xóa nhắc nhở thành công',
				DELETED_FAIL: 'Xóa nhắc nhở thất bị',
				UPDATED_SUCCESS: 'Cập nhật nhắc nhở thành công',
				UPDATED_FAIL: 'Cập nhật nhắc nhở thất bại',
				NAME_ALREADY_EXIST: 'Tên nhắc nhở đã tồn tại',
			},
			STORAGE: {
				DELETED_SUCCESS: "Xóa thành công",
				DELETED_FAIL: "Chức năng xóa bị lỗi",
				UPDATED_SUCCESS: "Sửa thành công",
				UPDATED_FAIL: "Chức năng sửa bị lỗi",
				CREATED_SUCCESS: "Tạo mới thành công",
				CREATED_FAIL: "Chức năng tạo mới bị lỗi",
				NAME_ALREADY_EXIST: "Tên đã tồn tại"
			}
		},
		STREAMAX: {
			STATUS_RESPONSE: {
				SUCCESS: 'Thành công',
				ILLEGAL_REQUEST: 'Yêu cầu không hợp lệ',
				SERVER_ERROR: 'Lỗi server',
				NO_OPERATION_RIGHTS: 'Không có quyền thực hiện hoạt động này',
				AUTHORIZATION_FAILED: 'Xác thực thất bại',
				ACCOUNT_EXPIRED: 'Tài khoản hết hạn truy cập',
				USERNAME_OR_PASSWORD_WRONG: 'Tài khoản hoặc mật khẩu sai',
				REQUEST_PARAMETER_COUNT_ERROR: 'Lỗi yêu cầu tham số ',
				REQUEST_FORMAT_ERROR: 'Lỗi định dạng ',
				AUTHORIZATION_KEY_NOT_DETECTED: 'Không tìm thấy khóa ủy quyền',
				AUTHORIZATION_KEY_WRONG: 'Khóa ủy quyền sai',
				PARAMETER_ERROR: 'Lỗi tham số truyền vào ',
				DATABASE_CONNECT_ERROR: 'Lỗi kết nối cơ sở dữ liệu ',
				DATABASE_OPERATION_ABNORMAL:'Hoạt động cơ sở dữ liệu bất thường',
				INTERNAL_INTERFACE_PARAMETER_ERROR:'Lỗi tham số giao diện nội bộ',
				DEVICE_SEARCH_RECORDING_CALENDAR_FAILED:'Lỗi tìm kiếm thời gian ghi lại',
				DEVICE_IS_NOT_ONLINE: 'Thiết bị không trực tuyến',
				DEVICE_QUERY_SERVICE_BUSY: 'Dịch vụ truy vấn thiết bị bận',
				DEVICE_EXECUTION_FAILED: 'Lỗi thực thi thiết bị',
				DEVICE_DO_NOT_EXISTS: 'Thiết bị không tồn tại',
				QUERY_DEVICE_FAILED: 'Truy vấn thiết bị lỗi',
			},
		},
		DEAFULT: {
			NOTHING_SELECTED: 'Không có dữ liệu'
		},
		DATA: {
			SENSOR: {
				ERROR: 'Giá trị không xác định'
			}
		},
    validation: {
      required: "{{field}} không được bỏ trống.",
      maxlength: "{{field}} chỉ được dài tối đa {{value}} ký tự",
      minlength: "{{field}} phải dài tối thiểu {{value}} ký tự",
      pattern: "{{field}} sai định dạng",
      onlyNaturalNumber: "{{field}} phải là số nguyên dương",
      onlyNumber: "{{field}} phải là số",
      onlyDoubleNumber: "{{field}} phải là kiểu số",
      onlyCharacter: "{{field}} chỉ được nhập ký tự chữ cái, chữ số và dấu _",
      mustMatch: "{{field}} không khớp",
      passwordMustMatchChange: "Mật khẩu mới và nhập lại mật khẩu mới không khớp",
      existCheck: "{{field}} đã tồn tại",
      email: "Không đúng định dạng email",
      mustSameUnit: "{{field}} phải cùng đơn vị",
      max: "{{field}} không hợp lệ ",
      min: "{{field}} không hợp lệ ",
      duplicate: "{{field}} đã tồn tại ",
    },
	},
};
